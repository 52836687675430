import React, { Component } from "react";
import './Home.module.css';

import Banner from './sections/Banner/Banner';
import Intro from './sections/Intro/Intro';
import Expertise from './sections/Expertise/Expertise';
import PastExp from './sections/PastExp/PastExp';
import Works from './sections/Works/Works';
import Contact from './sections/Contact/Contact';

class Home extends Component {
 
    render() {
          return (
          <div>
            <Banner/>
            <Intro/>
            <Expertise/>
            <PastExp/>
            <Works/>
            <Contact/>
          </div>
        );
    }
  }
  
  export default Home;