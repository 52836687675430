import React, { Component } from "react";
// import { Route } from "react-router-dom";

import SlideShow from '../component/SlideShow/SlideShow';
import Elaboration from '../component/Elaboration/Elaboration';

// import placeholderVid from '../../../assets/vid-placeholder.png';

import heroImgCirclets from "../../../assets/product/circlets-hero.JPG";
import img1Circlets from "../../../assets/product/circlets/circlets-1.JPG";
import img2Circlets from "../../../assets/product/circlets/circlets-2.JPG";
import img3Circlets from "../../../assets/product/circlets/circlets-3.JPG";
import img4Circlets from "../../../assets/product/circlets/circlets-4.JPG";

import heroImgZerashiki from "../../../assets/product/zerashiki-hero.JPG";
import img1Zerashiki from "../../../assets/product/zerashiki/zerashiki-1.jpg";
import img2Zerashiki from "../../../assets/product/zerashiki/zerashiki-2.jpg";
import img3Zerashiki from "../../../assets/product/zerashiki/zerashiki-3.png";
import img4Zerashiki from "../../../assets/product/zerashiki/zerashiki-4.jpg";
import vidZerashiki from "../../../assets/product/zerashiki/zerashiki-video-opt.mp4";

import heroImgLexigon from "../../../assets/product/lexigon-hero.jpg";
import img2Lexigon from "../../../assets/product/lexigon/lexigon-2.jpg";
import img3Lexigon from "../../../assets/product/lexigon/lexigon-3.jpg";
import img4Lexigon from "../../../assets/product/lexigon/lexigon-4.jpg";
import img5Lexigon from "../../../assets/product/lexigon/lexigon-5.jpg";
import img6Lexigon from "../../../assets/product/lexigon/lexigon-6.jpg";
import img7Lexigon from "../../../assets/product/lexigon/lexigon-7.jpg";
import img8Lexigon from "../../../assets/product/lexigon/lexigon-8.jpg";

import heroImgFlickering from "../../../assets/product/flickering-hero.jpg";
import img1Flickering from "../../../assets/product/flickering/flickering-1.jpg";
import img2Flickering from "../../../assets/product/flickering/flickering-2.jpg";

import classes from './Products.module.css';

export class Circlets extends Component {
     render() {
          return (
          <div className={classes.Projects}>
            <SlideShow 
              color="156, 92, 96"
              heroImage={heroImgCirclets}

              title="Circlets"
              category="Products"
              type="Jewelry"
              description="Three pieces of jewelry, all inspired by a simple circle, created by three ideation and manufacturing methods:
              the 3D printed Loop, the laser cut Annuluses and the handcrafted Wreath."
              heading1="Year"
              info1="2017"
              heading2="Instructor"
              info2="Patrick Chia"

              back="/projects/branding"
              next="/projects/zerashiki"
            />

            <Elaboration              
              style={{
                gridTemplateRows: "400px 400px 200px 200px 200px 200px 400px"
              }}
            >
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage: `url(${img1Circlets})`,
                  gridColumn: "1",
                }}
                ></div>

              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(156, 92, 96, 0.1)`,
                  gridColumn: "1",
                  gridRow: "2",
                }}       
                >
              {/* <div className={classes.Title}>Loop</div> */}
                <strong>Loop</strong>
                Loop is a 3D printed necklace generated by selective laser sintering. 
                The name 'loop' refers both to the coiled shape and the fact that it is self-closing,
                which made 3D printing the most suitable method to manufacture this seamless piece of jewelry.
                Instead of the common donut shape, the overall shape of Loop was inspired by a complex circular 3D
                object that was carefully selected after many rounds of moodboard assembly and ideation.
              </div>
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage:`url(${img3Circlets})`,
                  gridColumn: "2",
                  gridRow: "1 / span 3",
                }}
              ></div>

              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(156, 92, 96, 0.1)`,
                  gridColumn: "1",
                  gridRow: "7",
                }}
                >
                    {/* <div className={classes.Title}>Annuluses</div> */}
                    <strong>Annuluses</strong>
                    Annuluses is a pendant, the shape of which comprises of multiple circles overlapping
                    and interacting with one another. The ideation process to produce the final shape was
                    purely hands-on. Circles of different sizes and colors were put together in as many ways
                    as possible to generate the most visually pleasant designs. The final combination was then
                    recreated in Illustrator and sent for laser cutting, resulting in a piece of jewelry that import is
                    highly intricate yet very simple to produce.
                </div>

                <div 
                className={classes.ImgH}
                style={{
                  backgroundImage:`url(${img2Circlets})`,
                  gridColumn: "1",
                  gridRow: "3 / span 4",
                }}
                ></div>

              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(156, 92, 96, 0.1)`,
                  gridColumn: "2",
                  gridRow: "4 / span 2",
                }}     
                >
                    {/* <div className={classes.Title}>Wreath</div> */}
                    <strong>Wreath</strong>
                    Wreath is a highly eye-catching piece of accessory, comprising of a large circular
                    ring and a uniquely shaped pendant. The ideation process started with an extensive search for
                    inspiration from design books and magazines. The final design was inspired by several designs involving optical illusion.
                    The metallic details on the pendant may look flat at first glance, but when observed from another perspective, 
                    they become two 3D pyramid shapes with opposite orientations. 
                    
                </div>
              
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage:`url(${img4Circlets})`,
                  gridColumn: "2",
                  gridRow: "6 / span 2",
                }}>
              </div>
            </Elaboration>

          </div>
        );
    }
  }
  
  export class Zerashiki extends Component {
    render() {
         return (
         <div className={classes.Projects}>
           <SlideShow 
             color="142, 184, 179"
             heroImage={heroImgZerashiki}

             title="Zerashiki"
             category="Products"
             type="Leatherware"
             description="A collection of seamless leatherware where traditional leather handbag and ‘furoshiki’ –
              the Japanese traditional way of keeping things are combined."
             heading1="Year"
             info1="2017"
             heading2="Instructor"
             info2="Ash Y. S. Yeo"

             back="/projects/circlets"
             next="/projects/lexigon"
           />

            <Elaboration              
              style={{
                gridTemplateRows: "400px 400px 400px 400px 400px 400px"
              }}
            >
              <div 
                className={classes.ImgS}
                style={{
                  backgroundImage: `url(${img1Zerashiki})`,
                  backgroundSize: "contain",
                  gridColumn: "1",
                  gridRow: "2 / span 2",
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(142, 184, 179, 0.15)`,
                  gridColumn: "1",
                  gridRow: "1",
                }}       
              >This project started with a simple brief: to create something that sparks delight.
              The concept of Ikigai and Hygge were introduced by the instructor to guide our thinkings
              towards meaningful concepts such as meanings, purposes, simplicity and satisfaction.
              These concepts inspired me to reimagine the way we handle our everyday items - objects that
              shape our daily life and identity, yet are very often overlooked. I wanted to create special
              carrier that can make the users more aware of the special places these items hold in our life.
              </div>
              <div 
                className={classes.ImgS}
                style={{
                  backgroundImage: `url(${img2Zerashiki})`,
                  backgroundSize: "contain",
                  gridColumn: "1",
                  gridRow: "4 / span 2",
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(142, 184, 179, 0.15)`,
                  gridColumn: "1",
                  gridRow: "6",
                }}       
              >The outcome was a do-it-yourself bag which combined leathercraft with furoshiki 
              – the Japanese traditional way of keeping things.
              The design induces pleasure through a series of gestures involved in its daily usage, such as
              folding, slotting or tying knots.
              The user can enjoy the making process, seamlessly integrate the bag into their daily routine 
              and learn to appreciate their ordinary belongings.
              </div>
              <div 
                className={classes.ImgV}
                style={{
                  backgroundImage: `url(${img3Zerashiki})`,
                  // backgroundSize: "contain",
                  backgroundColor: "#eeeeee",
                  gridColumn: "2",
                  gridRow: "1 / span 3",
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(142, 184, 179, 0.15)`,
                  gridColumn: "2",
                  gridRow: "4",
                }}       
              >For this project, I worked closely with Nomad Leatherwear, a startup wishing to promote the maker movement. 
              My focus was therefore to encourage people to create their own leatherware, and the finalized idea was 
              a do-it-yourself leather bag with special places for everyday items.
              I started off by exploring all the different existing variations of this object, as well as the gestures involved.
              The goal was to create a design that is functional and convenient, yet delightful to interact with.</div>
              <div 
                className={classes.ImgS}
                style={{
                  backgroundImage: `url(${img4Zerashiki})`,
                  backgroundSize: "contain",
                  gridColumn: "2",
                  gridRow: "5 / span 2",
                }}
              ></div>
            </Elaboration>
            <video 
                width="100%" 
                height="100%" 
                controls
            >
              <source src={vidZerashiki} type="video/mp4"></source>
            </video>
         </div>
       );
   }
 }

 export class Lexigon extends Component {
  render() {
       return (
       <div className={classes.Projects}>
         <SlideShow 
           color="231, 173, 109"
           heroImage={heroImgLexigon}

           title="Lexigon"
           category="Products"
           type="Lighting"
           description="A lighting system inspired by a familiar type of fruit – the pineapple. 
           The flexagonal units can be combined to form different arrangements, serving a variety of functions and settings."
           heading1="Year"
           info1="2017"
           heading2="Instructor"
           info2="Christophe Gaubert"
           heading3="Featured in"
           info3="NUS DID Gradbook 2018"

           back="/projects/zerashiki"
           next="/projects/flickering"
         />

          <Elaboration              
            style={{
              gridTemplateRows: "repeat(19, 200px)"
            }}
          >
            <div 
              className={classes.ImgH}
              style={{
                backgroundImage: `url(${img2Lexigon})`,
                gridColumn: "2",
                gridRow: "1 / span 2",
              }}
            ></div>
            <div 
              className={classes.Text}
              style={{
                backgroundColor: `rgba(231, 173, 109, 0.1)`,
                gridColumn: "1",
                gridRow: "1 / span 2",
              }}       
            >Lexigon is a lighting system consisting of modular units joined together. 
            Each unit has the shape of a flexagon, a geometric 3D shape that can be flexed out or inwards over and over again, 
            revealing layer after layer. The LED light circuit is implanted within every unit, and magnet strips on the outer
            surface allow these units to snap perfectly into positions, connecting with one another. 
            There are innumerable possibilities in combining these units to form lighting systems of different shapes, sizes and functions. 
            </div>


            <div 
              className={classes.ImgV}
              style={{
                backgroundImage: `url(${img3Lexigon})`,
                gridColumn: "1",
                gridRow: "3 / span 6",
              }}
            ></div>
            <div 
              className={classes.ImgH}
              style={{
                backgroundImage: `url(${img4Lexigon})`,
                gridColumn: "2",
                gridRow: "5 / span 3",
              }}
            ></div>
            <div 
              className={classes.Text}
              style={{
                backgroundColor: `rgba(231, 173, 109, 0.1)`,
                gridColumn: "2",
                gridRow: "3 / span 2",
              }}       
            >
            The flexible nature of the flexagon allows each unit to transform and release different amounts of light. 
            The user hence can control the level of brightness of the light fixture according to their personal preference. 
            All the surfaces of the units are congruent triangles. As a result, any surfaces of 2 units can be put together 
            to establish the connection. This lets the user freely create the light fixture they desire with little constraint.
            Being a geometric 3D object, the flexagon has a modern and effortless look. The shape is seemingly simple, yet the 
            mechanism behind it is highly intriguing, making it an object that is both minimalist and intricate. 
            </div>


            <div 
              className={classes.ImgH}
              style={{
                backgroundImage: `url(${img6Lexigon})`,
                gridColumn: "1",
                gridRow: "11 / span 3",
              }}
            ></div>
            <div 
              className={classes.ImgV}
              style={{
                backgroundImage: `url(${img5Lexigon})`,
                gridColumn: "2",
                gridRow: "8 / span 7",
              }}
            ></div>
            <div 
              className={classes.Text}
              style={{
                backgroundColor: `rgba(231, 173, 109, 0.1)`,
                gridColumn: "1",
                gridRow: "9 / span 2",
              }}       
            >Accompanying these units are three variations of base, to be used in different types of light fixtures. 
            The base supplies the original voltage which will light up all the connecting units. 
            Every Lexigon fixture will require at least one base, which is connected to the power source. 
            There are 3 variations to be used in 3 different types of light fixtures: ceiling light, table light and wall light. 
            The bases are designed to be simple and minimal so that the attention will stay on the intricate structure formed by the units.
            </div>



            <div 
              className={classes.ImgV}
              style={{
                backgroundImage: `url(${img7Lexigon})`,
                gridColumn: "1",
                gridRow: "14 / span 6",
              }}
            ></div>
            <div 
              className={classes.ImgH}
              style={{
                backgroundImage: `url(${img8Lexigon})`,
                gridColumn: "2",
                gridRow: "17 / span 3",
              }}
            ></div>
            <div 
              className={classes.Text}
              style={{
                backgroundColor: `rgba(231, 173, 109, 0.1)`,
                gridColumn: "2",
                gridRow: "15 / span 2",
              }}       
            >Lexigon utilizes electromagnetic induction. As the first unit is connected to the power-source base, 
            a voltage will be induced in the circuit of other units that are brought near and attached to it, 
            enabling them to light up. Subsequently, any unit that is connected to these units will inherit the induced voltage 
            and light up as well. 
            Pairs of magnetic strips are embedded into the surfaces of each unit. 
            Since magnet is polar, there are positive strips, which lie beneath embossed sections, and negative strips, 
            which lie beneath debossed sections on the surfaces. The strips are positioned such that the attached unit 
            can snap into place in the right orientation.
            </div>

          </Elaboration>
       </div>
     );
 }
}

export class Flickering extends Component {
  render() {
       return (
       <div className={classes.Projects}>
         <SlideShow 
           color="188, 175, 166"
           heroImage={heroImgFlickering}

           title="Flickering"
           category="Products"
           type="Candle Holder"
           description="A candle holder inspired by the flickering movement of a flame, 
           influenced by Dutch fashion designer Iris van Herpen’s “Syntopia” (2018) collection"
           heading1="Year"
           info1="2018"
           heading2="Instructor"
           info2="Olivia Lee"
           heading3="Featured in"
           info3="NUS DID Gradbook 2019"

           back="/projects/lexigon"
           next="/projects/smile4speech"
         />

         <Elaboration              
           style={{
             gridTemplateRows: "1200px 400px 1200px"
           }}
         >
           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img1Flickering})`,
                // border: "grey 1px solid", 
                backgroundSize: "contain",
                gridColumn: "1 / span 2",
                gridRow: "1",
             }}
             ></div>

           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(188, 175, 166, 0.1)`,
               gridColumn: "1",
               gridRow: "2",
             }}       
            >             
            The design aims to honor the overlooked beauty of the candle flame, upholding its warm yet mysterious qualities. 
            The motion of the candle flame was studied, and the observed 2D shapes were then used to construct a 3D form, 
            which became the base unit of the final design.
            Symmetry and circular array were then applied into the arrangement to tone down its complexity.
            The notion of contrast was also considered. A simple, functional base for the candle completed the design,
            complementing the intricate and elaborate upper structure. 
           </div>

           <div 
             className={classes.ImgH}
             style={{
               backgroundImage:`url(${img2Flickering})`,
               backgroundSize: "contain",
              // border: "grey 1px solid", 
              gridColumn: "1 / span 2",
              gridRow: "3",
             }}
           ></div>

            <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(188, 175, 166, 0.1)`,
               gridColumn: "2",
               gridRow: "2",
             }}       
            >
            As the design brief involved drawing inspiration from Iris van Herpen, this candle holder was intended to be produced 
            specifically by 3D printing, one of her most iconic manufacturing methods.
            It was designed such that no other processes would be more suitable, and, without the concept of 3D printing, 
            the design itself would never be conceived.
            The aim was to demonstrate how valuable 3D printing is: it helps us break through boundaries 
            that used to limit our creativity, stimulates the beginnings of new ideas, and eventually materializes such ideas reliably. 
           </div>
           
         </Elaboration>

       </div>
     );
 }
}