import classes from './Service.module.css';
import React from 'react';

import Work from '../../component/Work/Work';

import smile4Speech from '../../../../assets/service/smile-hero.jpg';
import kakiCircle from '../../../../assets/service/kaki-hero.jpg';
// import placeholderImg from '../../../../assets/img-placeholder.png';

const service = () => {
    return (
        <div className={classes.WorkBoard} style={{minHeight:`${window.innerHeight-89-100-80}px`}}>
            <div className={classes.Column1}>
                <Work 
                    image={smile4Speech} 
                    backgroundPositionX="left"
                    // shadowColor="rgba(76, 116, 162, 0.2)" 
                    title="Smile For Speech" 
                    tag="/ Assist App"
                    onClick={() => window.open('/projects/smile4speech')}/>
            </div>

            <div className={classes.Column2}>
                <Work 
                    image={kakiCircle} 
                    // shadowColor="rgba(158, 46, 36, 0.2)" 
                    title="Kaki Circle" tag="/ Social App"
                    onClick={() => window.open('/projects/kaki-circle')}/>
            </div>
      </div> 
    )
}

export default service;