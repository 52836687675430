import classes from './Visual.module.css';
import React from 'react';

import Work from '../../component/Work/Work';

import caid from '../../../../assets/visual/caid-hero.jpg';
import interior from '../../../../assets/visual/interior-hero.jpg';
import sketch from '../../../../assets/visual/sketches-hero.jpg';

import aural from '../../../../assets/visual/aural-hero.jpg';
import swatch from '../../../../assets/visual/murakami-hero.jpg';
import branding from '../../../../assets/visual/branding-hero.gif';


const visual = () => {
    return (
        <div className={classes.WorkBoard}>
            <div className={classes.Column1}>
                <Work 
                    image={caid} 
                    // shadowColor="rgba(204, 115, 101, 0.2)" 
                    title="CAID" tag="/  Modelling & Rendering"
                    onClick={() => window.open('/projects/caid')}/>
                <Work 
                    image={interior} 
                    // shadowColor="rgba(217, 191, 152, 0.2)" 
                    title="Interior" tag="/  Interior Rendering"
                    onClick={() => window.open('/projects/interior')}/>
                <Work 
                    image={sketch} 
                    // shadowColor="rgba(174, 168, 161, 0.2)" 
                    title="Sketches" tag="/  Drawings"
                    onClick={() => window.open('/projects/sketches')}/>
            </div>

            <div className={classes.Column2}>
                <Work 
                    image={aural} 
                    // shadowColor="rgba(41, 47, 77, 0.2)" 
                    title="Aural Aid" tag="/  Visual Merchandising"
                    onClick={() => window.open('/projects/aural-aid')}/>
                <Work 
                    image={swatch} 
                    // shadowColor="rgba(174, 183, 214, 0.2)" 
                    title="Murakami X Swatch" tag="/  Graphic"
                    onClick={() => window.open('/projects/murakami-swatch')}/>
                <Work 
                    image={branding} 
                    // shadowColor="rgba(224, 7, 12, 0.2)" 
                    title="Branding Collection" tag="/  Branding"
                    onClick={() => window.open('/projects/branding')}/>

            </div>
      </div> 
    )
}

export default visual;