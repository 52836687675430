import classes from './UIUX.module.css';
import React from 'react';

import Work from '../../component/Work/Work';

import motion from '../../../../assets/uiux/motion-hero.JPG';
import sen from '../../../../assets/uiux/sen-hero.jpg';
import unfold from '../../../../assets/uiux/unfold-hero.jpg';
import musee from '../../../../assets/uiux/musee-hero.jpg';

// import placeholderImg from '../../../../assets/img-placeholder.png';


const uiux = () => {
    return (
        <div className={classes.WorkBoard}>
            <div className={classes.Column1}>
                <Work 
                    image={motion} 
                    // shadowColor="rgba(34, 33, 38, 0.2)" 
                    title="Shape of Motion" 
                    tag="/ Retail"
                    onClick={() => window.open('/projects/shape-of-motion')}/>
                <Work 
                    image={sen} 
                    // shadowColor="rgba(120, 140, 56, 0.2)" 
                    title="Sen (Forest)" tag="/ Playscape"
                    onClick={() => window.open('/projects/sen-forest')}/>
            </div>

            <div className={classes.Column2}>
                <Work 
                    image={unfold} 
                    // shadowColor="rgba(66, 147, 140, 0.2)" 
                    title="Unfold the Untold" tag="/ Retail"
                    onClick={() => window.open('/projects/unfold-the-untold')}/>
                <Work 
                    image={musee} 
                    // shadowColor="rgba(119, 105, 64, 0.2)" 
                    title="musee*" tag="/ Museum"
                    onClick={() => window.open('/projects/musee')}/>
            </div>
      </div> 
    )
}

export default uiux;