import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import Home from "../../pages/Home/Home";
import Works from "../../pages/Works/Works";
import {Circlets, Zerashiki, Lexigon, Flickering} from "../../pages/Projects/Products/Products";
import {Smile, Kaki} from "../../pages/Projects/Services/Services";
import {Motion, Unfold, Sen, Musee} from "../../pages/Projects/UIUX/UIUX";
import {CAID, Aural, Interior, Murakami, Sketches, Branding} from "../../pages/Projects/Visual/Visual";

import classes from './Content.module.css';

 
  class Content extends Component {
  
    render() {
          return (<div className={classes.Content}>
            <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/works" component={Works} />

                <Route path="/projects/circlets" component={Circlets} />
                <Route path="/projects/zerashiki" component={Zerashiki} />
                <Route path="/projects/lexigon" component={Lexigon} />
                <Route path="/projects/flickering" component={Flickering} />

                <Route path="/projects/smile4speech" component={Smile} />
                <Route path="/projects/kaki-circle" component={Kaki} />

                <Route path="/projects/shape-of-motion" component={Motion} />
                <Route path="/projects/unfold-the-untold" component={Unfold} />
                <Route path="/projects/sen-forest" component={Sen} />
                <Route path="/projects/musee" component={Musee} />

                
                <Route path="/projects/caid" component={CAID} />
                <Route path="/projects/aural-aid" component={Aural} />
                <Route path="/projects/interior" component={Interior} />
                <Route path="/projects/murakami-swatch" component={Murakami} />
                <Route path="/projects/sketches" component={Sketches} />
                <Route path="/projects/branding" component={Branding} />
              </Switch>
            </Router>
          </div>
        );
    }
  }
  
  export default Content;