import React, { Component } from "react";
import { Link } from 'react-router-dom';

import classes from './SlideShow.module.css';

class SlideShow extends Component {
 
    render() {
          return (
          <div className={classes.Projects}>
            <div className={classes.SlideShow} style={{color: `rgba(${this.props.color}, 1)`}}>
              <div 
                className={classes.Photo}
                style={{backgroundImage: `url(${this.props.heroImage})`, backgroundPositionY: `${this.props.imagePositionY}`, backgroundPositionX: `${this.props.imagePositionX}`}}     
            ></div>
              <div 
                className={classes.DetailColumn}
                style={{backgroundColor: `rgba(${this.props.color}, 1)`}}              
              >
                <div>
                  <p 
                    style={{fontFamily: "Roboto Thin", color: "white", textAlign: "left"}}
                  >{this.props.category} / <strong style={{fontFamily: "Roboto Regular"}}>{this.props.type}</strong></p>
                  <h1
                    style={{fontFamily: "Playfair Display Bold", marginBottom: 30}}
                  >{this.props.title}</h1>
                  <p style={{textAlign: "left", marginBottom: 30, fontStyle: "italic", width: "100%"}}>{this.props.groupmates}</p>
                </div>

                <p style={{textAlign: "left"}}>{this.props.description}</p>

              </div>
              <div 
                className={classes.DetailBar}
                style={{backgroundColor: `rgba(${this.props.color}, 0.2)`}}
              >
                <div className={classes.Block}>
                  <p style={{marginTop: 0}}>{this.props.heading1}</p>
                  <h5>{this.props.info1}</h5>
                </div>
                <div className={classes.Block}>
                  <p style={{marginTop: 0}}>{this.props.heading2}</p>
                  <h5>{this.props.info2}</h5>
                </div>
                <div className={classes.Block}>
                  <p style={{marginTop: 0}}>{this.props.heading3}</p>
                  <h5>{this.props.info3}</h5>
                </div>
                <div className={classes.Block}>
                  <p style={{marginTop: 0}}>{this.props.heading4}</p>
                  <h5>{this.props.info4}</h5>
                </div>
              </div>
              <Link 
                className={classes.Back}
                style={{backgroundColor: `rgba(${this.props.color}, 0.35)`, color: `rgba(${this.props.color}, 1)`}}   
                to={this.props.back}  
              >&#8592;</Link>
              <Link 
                className={classes.Next}
                style={{backgroundColor: `rgba(${this.props.color}, 0.5)`, color: `rgba(${this.props.color}, 1)`}} 
                to={this.props.next}      
              >&#8594;</Link>
            </div>
        </div>
        )
    }
}

export default SlideShow;