import classes from './Product.module.css';
import React from 'react';

import Work from '../../component/Work/Work';

import circletsImage from '../../../../assets/product/circlets-hero.JPG';
import zerashikiImage from '../../../../assets/product/zerashiki-hero.JPG';
import lexigonImage from '../../../../assets/product/lexigon-hero.jpg';
import flickeringImage from '../../../../assets/product/flickering-hero.jpg';

const product = () => {
    return (
        <div className={classes.WorkBoard}>
            <div className={classes.Column1}>
            <Work 
                    image={circletsImage} 
                    // shadowColor="rgba(156, 92, 96, 0.2)" 
                    title="Circlets" 
                    tag="/ Jewelry"
                    onClick={() => window.open('/projects/circlets')}
                />
                <Work 
                    image={zerashikiImage} 
                    // shadowColor="rgba(142, 184, 179, 0.2)" 
                    title="Zerashiki" 
                    tag="/ Leatherware"
                    onClick={() => window.open('/projects/zerashiki')}/>
            </div>

            <div className={classes.Column2}>
                <Work 
                    image={lexigonImage} 
                    // shadowColor="rgba(231, 173, 109, 0.2)" 
                    title="Lexigon" tag="/ Lighting"
                    onClick={() => window.open('/projects/lexigon')}/>
                <Work 
                    image={flickeringImage} 
                    // shadowColor="rgba(188, 175, 166, 0.2)" 
                    title="Flickering" tag="/ Candle Holder"
                    onClick={() => window.open('/projects/flickering')}/>
            </div>
      </div> 
    )
}

export default product;