import React from "react";
import classes from './Banner.module.css';

// import circletsHeroImg from '../../../../assets/product/circlets-hero.JPG';
// import flickeringHeroImg from '../../../../assets/product/flickering-hero.jpg';
// import lexigonHeroImg from '../../../../assets/product/lexigon-hero.jpg';
// import zerashikiHeroImg from '../../../../assets/product/zerashiki-hero.JPG';

function Banner() {
  // const slideArr = [circletsHeroImg, flickeringHeroImg, lexigonHeroImg, zerashikiHeroImg]; 
  // let i = 0;

  // window.onload = () => {
  //   let slide = document.querySelector(`.${classes.Slide}`);
  //   // console.log(slide);
  //   setInterval(() => {
  //     if (i < slideArr.length - 1){
  //       i++;
  //     } else {
  //       i = 0;
  //     };
  //     slide.style.backgroundImage = `url(${slideArr[i]})`;
  //     slide.style.transform = "opacity(0.5)";
  //     slide.style.transition = "opacity 0.5s ease 0s";
  //   }, 3000)
  // }

  return (
    <div className={classes.Banner}>  
        {/* <link rel="preload" href="../../../../assets/product/circlets-hero.JPG" as="image"></link>
        <link rel="preload" href="../../../../assets/product/flickering-hero.jpg" as="image"></link>
        <link rel="preload" href="../../../../assets/product/lexigon-hero.jpg" as="image"></link>
        <link rel="preload" href="../../../../assets/product/zerashiki-hero.JPG" as="image"></link> */}
        <div 
          className={classes.AnchorTag} 
          id="homeTag" 
          style={{position: "absolute", top: 0 , width: "100%", height: 5, backgroundColor: "black", visibility: "hidden"}}
        ></div>
        <div className={classes.Slides} id="slidesContainer">
          <div 
            className={classes.Slide}
            // onLoad={loadHandler()}
          ></div>
        </div>
        <div className={classes.Quote}>
            <h1 className={classes.QuoteText}>
                " ... there is always{"\n"}one more thing to learn "
            </h1>
        </div>
        <a className={classes.ShowAll} href="/works/all"><p>All Projects         
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="-20 -8 40 40">
            <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/>
          </svg>
        </p></a>
    </div>
  );
}

export default Banner;
