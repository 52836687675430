import React from "react";
import classes from './Expertise.module.css';

function Expertise() {
  return (
    <div className={classes.Expertise}>
      <h1 className={classes.Title}>Areas of Mastery</h1>
      <div className={classes.Areas}>
        <div className={classes.TwoD}>
          <div className={classes.TwoDIcon}></div>
          <h4>2D Language</h4>
          <p>Photoshop |  Illustrator (2016)</p>
          <p>Indesign | After Effects (2018)</p>
          <p>Premiere Pro | XD | Axure (2019)</p>
        </div>
        <div className={classes.ThreeD}>
          <div className={classes.ThreeDIcon}></div>
          <h4>3D Language</h4>
          <p>Rhino | Keyshot (2016)</p>
          <p>Vray | 3ds Max (2017)</p>
          <p>Blender | Unity (2020)</p>
        </div>
        <div className={classes.Coding}>
          <div className={classes.CodingIcon}></div>
          <h4>Coding Language</h4>
          <p>HTML | CSS (2018)</p>
          <p>JavaScript | React (2020)</p>
          <p>C# (2021)</p>
        </div>
      </div>
      <h4 className={classes.Sentence}>... and are still expanding</h4>
  </div>
  )
}

export default Expertise;