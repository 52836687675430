import React, { Component } from "react";

import SlideShow from '../component/SlideShow/SlideShow';
import Elaboration from '../component/Elaboration/Elaboration';

// import placeholderImg from '../../../assets/img-placeholder.png';
// import placeholderVid from '../../../assets/vid-placeholder.png';

import heroImgMotion from "../../../assets/uiux/motion-hero.JPG";
import img4Motion from "../../../assets/uiux/motion/motion-4.jpg";
import img7Motion from "../../../assets/uiux/motion/motion-7.jpg";
import img2Motion from "../../../assets/uiux/motion/motion-2.jpg";
import img1Motion from "../../../assets/uiux/motion/motion-1.gif";
import vidMotion from "../../../assets/uiux/motion/motion-video-opt.mp4"
// import img3Motion from "../../../assets/uiux/motion/motion-3.jpg";
// import img5Motion from "../../../assets/uiux/motion/motion-5.jpg";
// import img6Motion from "../../../assets/uiux/motion/motion-6.jpg";

import heroImgUnfold from "../../../assets/uiux/unfold-hero.jpg";
import img1Unfold from "../../../assets/uiux/unfold/unfold-1.jpg";
import img2Unfold from "../../../assets/uiux/unfold/unfold-2.jpg";
import img3Unfold from "../../../assets/uiux/unfold/unfold-3.png";
import img4Unfold from "../../../assets/uiux/unfold/unfold-4.jpg";
import img5Unfold from "../../../assets/uiux/unfold/unfold-5.png";
import img6Unfold from "../../../assets/uiux/unfold/unfold-6.png";
import img7Unfold from "../../../assets/uiux/unfold/unfold-7.png";
import img8Unfold from "../../../assets/uiux/unfold/unfold-8.png";
import vid1Unfold from "../../../assets/uiux/unfold/unfold-video-1-opt.mp4";
import vid2Unfold from "../../../assets/uiux/unfold/unfold-video-2-opt.mp4";

import heroImgSen from "../../../assets/uiux/sen-hero.jpg";
import img1Sen from "../../../assets/uiux/sen/sen-1.jpg";
import img2Sen from "../../../assets/uiux/sen/sen-2.jpg";
import img3Sen from "../../../assets/uiux/sen/sen-3.jpg";
import img4Sen from "../../../assets/uiux/sen/sen-4.png";
import img5Sen from "../../../assets/uiux/sen/sen-5.jpg";
import img6Sen from "../../../assets/uiux/sen/sen-6.jpg";
import img7Sen from "../../../assets/uiux/sen/sen-7.jpg";
import img8Sen from "../../../assets/uiux/sen/sen-8.jpg";

import heroImgMusee from "../../../assets/uiux/musee-hero.jpg";
import img1Musee from "../../../assets/uiux/musee/musee-1.jpg";
import img2Musee from "../../../assets/uiux/musee/musee-2.jpg";
import img3Musee from "../../../assets/uiux/musee/musee-3.gif";
import img4Musee from "../../../assets/uiux/musee/musee-4.JPG";
import img5Musee from "../../../assets/uiux/musee/musee-5.jpg";
import vidMusee from "../../../assets/uiux/musee/musee-video-opt.mp4"

import classes from './UIUX.module.css';

export class Motion extends Component {
     render() {
          return (
          <div className={classes.Projects}>
            <SlideShow 
              color="34, 33, 38"
              heroImage={heroImgMotion}

              title="Shape of Motion"
              category="UI/UX"
              type="Retail Experience"
              description="An interactive booth that allows users to design their sneakers with patterns generated by movements in sports."
              heading1="Year"
              info1="2018"
              heading2="Instructor"
              info2="Yuta Nakayama"

              back="/projects/kaki-circle"
              next="/projects/unfold-the-untold"
            />

            <Elaboration              
              style={{
                gridTemplateRows: "400px 200px 400px 400px 600px"
              }}
            >
              <div 
                className={classes.ImgH}
                style={{
                  backgroundColor: "black",
                  backgroundImage: `url(${img1Motion})`,
                  gridColumn: "1",
                  gridRow: "1 / span 2",
                  backgroundSize: "contain",
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(34, 33, 38, 0.1)`,
                  gridColumn: "2",
                  gridRow: "1",
                }}       
              >
                In this project, we imagined ourselves creating an engaging digital interactive experience for Nike, 
                a forward-thinking sports brand. Browsing through Nike history, we identified three ideas that this brand upholds: artistic qualities, self-expression,
                 and passion for sports. These became the key concepts that helped us build our experience, with which we aimed to highlight the aesthetic aspect of sports, 
                 to use sports movements as a kind of language for self expression, and to kindle the passion for sports in every user. 
              </div>

              <div 
                className={classes.ImgH}
                style={{
                  backgroundColor: "black",
                  backgroundImage: `url(${img2Motion})`,
                  gridColumn: "2",
                  gridRow: "2 / span 2",
                  backgroundSize: "contain",
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(34, 33, 38, 0.1)`,
                  gridColumn: "1",
                  gridRow: "3",
                }}       
              >
                The interactive surface has 3 zones: ‘Starting’ (holding a set of cue cards), ‘Exploring’ and ‘Creating’. By moving cards into ‘Exploring’ zone, users will see world-famous 
                athletes such as Roger Federer or Neymar Jr create aesthetic patterns with their sports movements. Moving the cards into the ‘Creating’ zone, user can mix 
                and match the patterns to customize their sports shoes. Once they are pleased with the result, they just need to scan the QR code below the shoe to purchase. 
              </div>

              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage: `url(${img7Motion})`,
                  gridColumn: "1",
                  gridRow: "4",
                  backgroundSize: "contain",
                  backgroundColor: "rgba(7, 9, 10, 1)"
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(34, 33, 38, 0.1)`,
                  gridColumn: "2",
                  gridRow: "4",
                }}       
              >
                Although this project involves very simple interactions, the principle behind it can inspire much more exciting and novel experiences if more 
                time, resources and technical expertise are invested. For example, a motion sensor can be incorporated, allowing users to ‘paint’ on the merchandise with their own, unique movements.
                In another scenario, movements of a star player can be recorded in form of patterns and printed on the merchandise, creating a unique memento that marks a momentous game. 
              </div>
              <div 
                className={classes.ImgL}
                style={{
                  backgroundImage: `url(${img4Motion})`,
                  gridColumn: "1/span 2",
                  gridRow: "5",
                  // backgroundSize: "contain",
                  backgroundColor: "rgba(0, 0, 0, 0.05)"
                }}
              ></div>
            </Elaboration>
            <video 
                width="100%" 
                style={{
                  backgroundColor: "black",
                  gridColumn: "1 / span 2",
                  gridRow: "7 / span 1",
                }}
                controls
            >
              <source src={vidMotion} type="video/mp4"></source>
            </video>
          </div>
        );
    }
  }

  export class Unfold extends Component {
    render() {
         return (
         <div className={classes.Projects}>
           <SlideShow 
             color="66, 147, 140"
             heroImage={heroImgUnfold}
             imagePositionY="center"
             title="Unfold the Untold"
             category="UI/UX"
             type="Retail Experience"
             groupmates="Design with Ngieng Jia'en & Winnie Lim"
             description="Upon test-stamping a fragrance on the tester bracelet, a geometric code will be revealed allowing the user to explore the universe of the fragrance beyond the retail boundary."
             heading1="Year"
             info1="2018"
             heading2="Instructor"
             info2="Christophe Gaubert"

             back="/projects/shape-of-motion"
             next="/projects/sen-forest"
           />

           <Elaboration              
             style={{
               gridTemplateRows: "repeat(6, 400px)",
               gridTemplateColumns: "1fr 1fr 2fr"
             }}
           >
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img1Unfold})`,
                 gridColumn: "1/span 2",
                 gridRow: "1",
                 backgroundSize: "contain",
               }}
             ></div>
            <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img2Unfold})`,
                 gridColumn: "1/span 2",
                 gridRow: "2",
                 backgroundSize: "contain",
               }}
             ></div>
             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(66, 147, 140, 0.1)`,
                 gridColumn: "3",
                 gridRow: "1",
               }}       
             >
               In most fragrance retail stores, the first encounter with scents usually begins with a confusing
               mixture of scents that lingers in the air. This is due to the need to test spray on paper and necessarily
               on the skin for an accurate representation of the fragrance. Overtime, customers may carry multiple
               scents on their bodies, causing the impression of each individual scent to diminish. Hence, our goals include 
               minimizing the diffusion of scent in the space, facilitating easy removal of scents from the body, 
               and reinforcing olfactory memory.
             </div>

            <video 
                className={classes.SmallVid}
                width="100%" 
                height="100%" 
                style={{
                  backgroundColor: `rgba(66, 147, 140, 0.1)`,
                  gridColumn: "3",
                  gridRow: "2",
                }}
                controls
            >
              <source src={vid1Unfold} type="video/mp4"></source>
            </video>

             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(66, 147, 140, 0.1)`,
                 gridColumn: "3",
                 gridRow: "3",
               }}       
             >Upon entering the store, the customer will be enticed by a family of scent stamps.
             Each scent is embodied in a unique form, which labeless to allow the expansion of imagination.
             Customer can directly explore a scent by smelling the scent treat, or by stamping on the tester 
             pack. The packaging of the tester pack shares a similar color gradient as the corresponding scent stamp.
             The packaging and the tester bracelet are also designed with graphical details that represent the unique 
             traits of the scent.
             </div>
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img3Unfold})`,
                 gridColumn: "1/span 2",
                 gridRow: "3",
                 // backgroundSize: "contain",
               }}
             ></div>
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img4Unfold})`,
                 gridColumn: "3",
                 gridRow: "4",
                 // backgroundSize: "contain",
               }}
             ></div>
             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(66, 147, 140, 0.1)`,
                 gridColumn: "1/span 2",
                 gridRow: "4",
               }}       
             >
               Upon stampting, a geometric code printed in hydrophobic ink is revealed. As the tester can be easily 
               transformed into a bracelet, user can wear it and scan the code on it using an app. The code will unlock an 
               augmented reality experience designed specifically for the scent. With a swipe-up gesture,
               the user will be redirected to the scent profile, which contains information such as key persona, strength and impact, 
               chronological transformation and suitable occasions for the scent. This further reinforces the preciousness and uniqueness
               of the scent.  
             </div>
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img5Unfold})`,
                 gridColumn: "1/span 2",
                 gridRow: "5",
                 // backgroundSize: "contain",
               }}
             ></div>
             
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img6Unfold})`,
                 gridColumn: "3",
                 gridRow: "5",
                 // backgroundSize: "contain",
               }}
             ></div>

            <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(66, 147, 140, 0.1)`,
                 gridColumn: "3",
                 gridRow: "6",
               }}       
             >
               Each scent stamp comes with a case and a standardized perfume cartridge. With a straightforward
               mechanism, the solution is highly scalable to accommodate various brands. It can easily embody the geometric facets
               of Issey Miyake, or incarnate the icon curve and flower of Kenzo, creating unique identities for each brand. With subtle
               yet purposeful variations in colors and forms, each stamp upholds the unique identity of the scent it carries, while maintains
               a visual harmony with the rest of its family. 
             </div>
             <div 
               className={classes.ImgS}
               style={{
                 backgroundImage: `url(${img8Unfold})`,
                 gridColumn: "1",
                 gridRow: "6",
                 // backgroundSize: "contain",
               }}
             ></div>
             <div 
               className={classes.ImgS}
               style={{
                 backgroundImage: `url(${img7Unfold})`,
                 gridColumn: "2",
                 gridRow: "6",
                 // backgroundSize: "contain",
               }}
             ></div>
           </Elaboration>
           <video 
                width="100%" 
                controls
            >
              <source src={vid2Unfold} type="video/mp4"></source>
            </video>
         </div>
       );
   }
 }

 export class Sen extends Component {
  render() {
       return (
       <div className={classes.Projects}>
         <SlideShow 
           color="72, 82, 48"
           heroImage={heroImgSen}
           imagePositionY="center"
           title="Sen (Forest)"
           category="UI/UX"
           type="Playscape"
           groupmates="Design with Li Si Yi & Gui Xixian"
           description="A playscape that embodies the essence of tropical rainforest, with 5 modular units that can work together or independently."
           heading1="Year"
           info1="2019"
           heading2="Instructor"
           info2="Christophe Gaubert"
           heading3="Collaborator"
           info3="NTUC First Campus"
           heading4="Featured in"
           info4="NUSDID Gradbook 2020"

           back="/projects/unfold-the-untold"
           next="/projects/musee"
         />

         <Elaboration              
           style={{
             gridTemplateRows: "400px 400px 1000px 400px 500px 400px 1600px 1000px 400px 1000px",
             gridTemplateColumns: "1fr 1fr"
           }}
         >

          <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img1Sen})`,
               gridColumn: "1",
               gridRow: "1",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(72, 82, 48, 0.1)`,
               gridColumn: "2",
               gridRow: "1",
             }}       
           >
             Tropical rainforests used to be the natural biotope that made up Singapore. 
             However, due to many years of land cultivation and urbanization, 95% of them have been cleared. 
             Nowadays, children in Singapore have very few opportunities to explore this iconic biotope. 
             Encounter with this natural habitat is a great gateway for children to get to know nature, 
             setting the foundation for their understanding of more complex issues regarding sustainability and the environment. 
           </div>

           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img2Sen})`,
               gridColumn: "2",
               gridRow: "2",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(72, 82, 48, 0.1)`,
               gridColumn: "1",
               gridRow: "2",
             }}       
           >
             Motivated by the benefits that interactions with this biotope can offer, 
             SĒN is designed with an aim in mind: to bring the essence of tropical rainforests into the playground for children to explore. 
             Tropical rainforests are among the richest ecosystems in the world - they are always full of amazing species and happenings. 
             Exposure to this habitat at an early age will initiate fond memories, which can strengthen the future generation’s affinity 
             and respect towards nature. 
           </div>

           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img3Sen})`,
               gridColumn: "1/span 2",
               gridRow: "3",
              //  backgroundSize: "contain",
             }}
           ></div>

          <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(72, 82, 48, 0.1)`,
               gridColumn: "2",
               gridRow: "4",
             }}       
           >
             Taking into consideration the potential physical constraints, which are common in Singapore, the play structures are modular.
             They can stand together and form a coherent play experience in vast spaces, and when the available space is more limited, 
             these structures can stand alone while still offering the same enjoyable experience. 
             The play structures are also offered in variety, with each variation purposefully designed to benefit the children’s motor 
             and cognitive developement in specific ways.
           </div>

            <div 
             className={classes.ImgH}
             style={{
              backgroundColor: `rgba(72, 82, 48, 0.1)`,
               backgroundImage: `url(${img4Sen})`,
               gridColumn: "1",
               gridRow: "4",
               backgroundSize: "contain",
             }}
           ></div>

            <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(72, 82, 48, 0.1)`,
               gridColumn: "1",
               gridRow: "6",
             }}       
           >
            Each structure has a net made from hemp, a natural and non-toxic material that is also durable.   
            Following the shape of the metal frames, the net can support a wide range of activities. 
            It also acts as a barrier which lets children interact with the plants beneath without destroying them.
            <br/>
            <br/>
            The observation deck is a circular bench placed around the central tree, supported by the surrounding play structures.
           </div>

           <div 
             className={classes.ImgL}
             style={{
               backgroundImage: `url(${img5Sen})`,
               gridColumn: "1/span 2",
               gridRow: "5",
               backgroundSize: "contain",
             }}
           ></div>

           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(72, 82, 48, 0.1)`,
               gridColumn: "2",
               gridRow: "6",
             }}       
           >
            Children can sit together on the bench to observe the exterior of the central tree as well as the different species 
            inhabiting the tree.
            <br/>
            <br/>
            The polyester shelter allows the children to observe the central tree through a more abstract lens. 
            Watching the movements of the shadows projected onto the canvas, children can let their imagination 
            run free and weave their own narratives.
           </div>

           <div 
             className={classes.ImgV}
             style={{
               backgroundImage: `url(${img6Sen})`,
               gridColumn: "1/span 2",
               gridRow: "7",
               backgroundSize: "contain"
             }}
           ></div>

          <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(72, 82, 48, 0.1)`,
               gridColumn: "1",
               gridRow: "9",
             }}       
           >
            The play structures are modular and can also work independently. 
            When there are physical constraints to the landscape, each structure has an intentional cut out on the shelter to accommodate
             smaller trees. This cut out helps to direct children’s attention to the densest part of the tree, and together with the 
             purposefully placed plants, gives the children an immersive experience among nature. 
           </div>

            <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img7Sen})`,
               gridColumn: "1/span 2",
               gridRow: "8",
             }}
           ></div>

           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(72, 82, 48, 0.1)`,
               gridColumn: "2",
               gridRow: "9",
             }}       
           >
             Below the shelter, the nets will work seamless around the tree to facilitate interaction with nature. 
             They create a sensory learning experience as children can not only observe the plant beneath closely, but also
              smells and touch them.
             The distinctive nets of each modular unit provide freedom for children to decide their own play activities, 
             hence creating a non-discriminatory space suitable for children of all ages.
           </div>

           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img8Sen})`,
               gridColumn: "1/span 2",
               gridRow: "10",
             }}
           ></div>
         </Elaboration>
       </div>
     );
 }
}

export class Musee extends Component {
  render() {
       return (
       <div className={classes.Projects}>
         <SlideShow 
           color="117, 107, 57"
           heroImage={heroImgMusee}
           imagePositionY="center"
           title="musee*"
           category="UI/UX"
           type="Museum"
           description="A pro-learning museum journey  for young children in which knowledge is the key to unlock exciting, memorable and meaningful experiences."
           heading1="Year"
           info1="2019-2021"
           heading2="Supervisor"
           info2="Christophe Gaubert"
           heading3="Supported by"
           info3="Design Incubation Centre"

           back="/projects/sen-forest"
           next="/projects/caid"
         />

         <Elaboration              
           style={{
             gridTemplateRows: "400px 400px 1000px 400px 1000px 1000px 400px 400px",
             gridTemplateColumns: "1fr 1fr"
           }}
         >

          <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img1Musee})`,
               gridColumn: "1",
               gridRow: "1/span 2",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(117, 107, 57, 0.1)`,
               gridColumn: "2",
               gridRow: "1",
             }}       
           >
             Musee* started with my personal experience as a museum enthusiast. 
             For me, a trip to the museum is always exciting as it’s filled with opportunities to learn new things. 
             However, in reality, I never seemed to learn or remember as much about the visit much as I wish to. 
             This makes the museum experience somewhat unsatisfactory for me.
             I started to observe and talk to others about my experience, and learn that they share the same situation, 
             especially for family with young children.
           </div>

           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img2Musee})`,
               gridColumn: "1/span 2",
               gridRow: "3",
               backgroundSize: "contain"
             }}
           ></div>

           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(117, 107, 57, 0.1)`,
               gridColumn: "2",
               gridRow: "2",
             }}       
           >The texts in museum, which convey most of the info, can be overwhelming for adults, let alone children.
           Some museums attempted to solve this problem with physical and digital interactions, which end up stealing the spotlight. 
           Visitors may love to play around with these interactions, but the knowledge behind them are not always delivered
           There is also a lack of opportunities for parents, especially those with little knowledge about the subject exhibited, to interact
           with their children.
           </div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(117, 107, 57, 0.1)`,
               gridColumn: "1",
               gridRow: "4",
             }}       
           >
            In short, current museum experiences are not optimized for learning, and many opportunities to pass on knowledge are missed.
            Musee* is hence designed to improve the museum experience by tackling these issues. It digitalizes some information, while also
             prompts the visitors to explore the information available in the physical exhibition.
            It ensures that interaction and knowledge - not just interaction alone - work hand-in-hand to unlock exciting experiences.
            Finally, it takes parent into account by giving them the role of a guide/co-participant in their child’s learning experience.
           </div>

           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img3Musee})`,
               gridColumn: "1/span 2",
               gridRow: "5",
               backgroundSize: "90%",
               backgroundColor: `rgba(237, 235, 233, 1)`,
             }}
           ></div>
           
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(117, 107, 57, 0.1)`,
               gridColumn: "2",
               gridRow: "4",
             }}       
           >
            Currently musee* offers 12 different typologies of activities, varying in level of difficulty and gamification.
            There are straightforward activities like quizzes, fun facts or watching videos; slightly more complex one 
            like arranging elements of a diagram, distinguishing exhibits, putting together a subject or a scenario from their components,
            as well as game-like activities such as spot-the-differences, puzzle game, timed tasks and flipping cards.
            All these activities have 1 thing in common, which is their ability to be adapted to delivering any kind of content.
           </div>

            <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img4Musee})`,
               gridColumn: "1/span 2",
               gridRow: "6",
             }}
           ></div>

            <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(117, 107, 57, 0.1)`,
               gridColumn: "1",
               gridRow: "7",
             }}       
           >
            When we think about museum experiences, we tend to focus too much on what happens within the museum boundaries and forget
            about what can happen before or after a visit. 
            The bring-home extension is a very important part of the experience. Although it does not physically occur in the museum,  
            it gives museums the opportunities to stay in touch with their visitors, to educate by providing in-depth knowledge and 
            covering all the different relevant aspects of a topic. This is something that is very difficult to achieve within a single visit.
           </div>

           <div 
             className={classes.ImgS}
             style={{
               backgroundImage: `url(${img5Musee})`,
               backgroundPositionX: "right",
               gridColumn: "2",
               gridRow: "7/span 2",
             }}
           ></div>

            <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(117, 107, 57, 0.1)`,
               gridColumn: "1",
               gridRow: "8",
             }}       
           >
            Overpacking a visit with too many new information can make the museum experience overwhelming and discouraging for the visitors,
            especially young children or those with little knowledge but wish to know more. Therefore,  
            instead of putting all the learning in one visit, musee's main strategy is to offer knowledge daily in manageable portions 
            through fun activities, so that learning will soon become an enjoyable habit that motivates users to keep coming back 
            to the museum to learn more.
           </div>
         </Elaboration>
         <video 
                style={{backgroundColor: "black"}}
                width="100%" 
                controls
            >
              <source src={vidMusee} type="video/mp4"></source>
            </video>
       </div>
     );
 }
}