import classes from './UpButton.module.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const upButton = () => {
    return (
        <div 
            className={classes.UpButton}
            onClick={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
        >
            <FontAwesomeIcon icon={faChevronUp} />
        </div>
    )
}

export default upButton;