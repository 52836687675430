import classes from './Work.module.css';
import React from 'react';

const work = (props) => {
    return (
        <div className={classes.Work}>
            <div 
                className={classes.Image}
                style={{
                    backgroundImage: `url(${props.image})`,
                    backgroundPositionX: props.backgroundPositionX,
                    boxShadow: `15px 15px ${props.shadowColor}`
                }}
                onClick={props.onClick}
            ></div>
            <div className={classes.Label}>
                <div className={classes.Title}>{props.title}</div>
                <div className={classes.Tag}>{props.tag}</div>
            </div>
        </div>
    )
}

export default work;