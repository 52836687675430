import classes from './NavBar.module.css';
import React from 'react';

const navBar = () => {
    // function setCurrentPage(obj){
    //     const btnArr = document.querySelectorAll(`.${classes.Button}`);
    //     btnArr.forEach((btn) => {
    //         btn.className = classes.Button;
    //     });
    //     obj.target.className = classes.Button + ' ' + classes.ClickedButton;
    // }

    return (
        <div className={classes.NavBar}>
            <div className={classes.Logo}></div>
            <div className={classes.Buttons}>
                <a 
                    className={classes.Button} 
                    href='/#homeTag'                   
                >Home</a>
                <a 
                    className={classes.Button} 
                    href='/#aboutTag'
                >About</a>
                <a 
                    className={classes.Button} 
                    href='/#worksTag'
                >Works</a>
                <a 
                    className={classes.Button} 
                    href='/#contactTag'
                >Contact</a>
            </div>

        </div>
    )
}

export default navBar;