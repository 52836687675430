import React, { Component } from "react";

import classes from './Elaboration.module.css';

class Elaboration extends Component {
     render() {
          return (
          <div 
            className={classes.Elaboration}
            style={this.props.style}
          >
            {this.props.children}
          </div>
        );
    }
  }
  
  export default Elaboration;