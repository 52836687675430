import classes from './Footer.module.css';
import React from 'react';

const footer = () => {
    // function setCurrentPage(obj){
    //     const btnArr = document.querySelectorAll(`.${classes.Button}`);
    //     btnArr.forEach((btn) => {
    //         btn.className = classes.Button;
    //     });
    //     obj.target.className = classes.Button + ' ' + classes.ClickedButton;
    // }

    // console.log(window.location.href.indexOf('service'));
    return (
        <footer className={classes.Footer}
            // style={window.location.href.indexOf('service') !== -1 ? {position: "absolute", bottom: 0} : {}}
        > <small>Copyright &copy; 2021. Created by Anh Nguyen</small> </footer> 
    )
}

export default footer;