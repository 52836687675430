import React from "react";
import classes from './Works.module.css';

function Works() {
  return (
    <div className={classes.Works}>
      <div 
        id="worksTag" 
        style={{position: "absolute", top: -80 , width: "100%", height: 5, backgroundColor: "black", visibility: "hidden"}}
      ></div>
      <h1 className={classes.Title}>My Works</h1>
      <div className={classes.Areas}>

        <div className={classes.Product}>
          <div className={classes.ProductImg} onClick={() => {window.location.href = "/works/product"}}></div>
          <div className={classes.Label}>
            <h4>Product Design</h4>
            <p>/ 4 items</p>
          </div>
        </div>

        <div className={classes.Service}>
          <div className={classes.ServiceImg} onClick={() => {window.location.href = "/works/service"}}></div>
          <div className={classes.Label}>
            <h4>Service Design</h4>
            <p>/ 2 items</p>
          </div>

        </div>
        <div className={classes.UIUX}>
          <div className={classes.UIUXImg} onClick={() => {window.location.href = "/works/uiux"}}></div>
          <div className={classes.Label}>
            <h4>UI/UX Design</h4>
            <p>/ 4 items</p>
          </div>
        </div>

        <div className={classes.Visual}>
          <div className={classes.VisualImg} onClick={() => {window.location.href = "/works/visual"}}></div>
          <div className={classes.Label}>
            <h4>Visual Design</h4>
            <p>/ 8 items</p>           
          </div>
        </div>

      </div>
  </div>
  )
}

export default Works;