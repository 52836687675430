import React, { Component } from "react";
import { Route } from "react-router-dom";


import classes from './Works.module.css';

import SideBar from "./sections/SideBar/SideBar";

import Product from "./sections/Product/Product";
import Service from "./sections/Service/Service";
import UIUX from "./sections/UIUX/UIUX";
import Visual from "./sections/Visual/Visual";
import All from "./sections/All/All";

class Works extends Component {
 
    render() {
          return (
          <div className={classes.Works}>
            <SideBar/>
            <Route exact path="/works/all" component={All} />
            <Route exact path="/works/product" component={Product} />
            <Route exact path="/works/service" component={Service} />
            <Route exact path="/works/uiux" component={UIUX} />
            <Route exact path="/works/visual" component={Visual} />
          </div>
        );
    }
  }
  
  export default Works;