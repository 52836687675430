import classes from './All.module.css';
import React from 'react';

import Work from '../../component/Work/Work';

import circletsImage from '../../../../assets/product/circlets-hero.JPG';
import zerashikiImage from '../../../../assets/product/zerashiki-hero.JPG';
import lexigonImage from '../../../../assets/product/lexigon-hero.jpg';
import flickeringImage from '../../../../assets/product/flickering-hero.jpg';

import smile4Speech from '../../../../assets/service/smile-hero.jpg';
import kakiCircle from '../../../../assets/service/kaki-hero.jpg';

import motion from '../../../../assets/uiux/motion-hero.JPG';
import sen from '../../../../assets/uiux/sen-hero.jpg';
import unfold from '../../../../assets/uiux/unfold-hero.jpg';
import musee from '../../../../assets/uiux/musee-hero.jpg';

import caid from '../../../../assets/visual/caid-hero.jpg';
import interior from '../../../../assets/visual/interior-hero.jpg';
import sketch from '../../../../assets/visual/sketches-hero.jpg';

import aural from '../../../../assets/visual/aural-hero.jpg';
import swatch from '../../../../assets/visual/murakami-hero.jpg';
import branding from '../../../../assets/visual/branding-hero.gif';

const all = () => {
    return (
        <div className={classes.WorkBoard}>
            <div className={classes.Column1}>
                <Work 
                    image={circletsImage} 
                    // shadowColor="rgba(156, 92, 96, 0.2)" 
                    title="Circlets" 
                    tag="/ Jewelry"
                    onClick={() => window.open('/projects/circlets')}
                />
                <Work 
                    image={caid} 
                    // shadowColor="rgba(204, 115, 101, 0.2)" 
                    title="CAID" 
                    tag="/  Modelling & Rendering"
                    onClick={() => window.open('/projects/caid')}/>
                <Work 
                    image={sketch} 
                    // shadowColor="rgba(174, 168, 161, 0.2)" 
                    title="Sketches" 
                    tag="/  Drawings"
                    onClick={() => window.open('/projects/sketches')}/>
                <Work 
                    image={zerashikiImage} 
                    // shadowColor="rgba(142, 184, 179, 0.2)" 
                    title="Zerashiki" 
                    tag="/ Leatherware"
                    onClick={() => window.open('/projects/zerashiki')}/>
                <Work 
                    image={motion} 
                    // shadowColor="rgba(34, 33, 38, 0.2)" 
                    title="Shape of Motion" 
                    tag="/ Retail"
                    onClick={() => window.open('/projects/shape-of-motion')}/>
                <Work 
                    image={smile4Speech} 
                    backgroundPositionX="left"
                    // shadowColor="rgba(76, 116, 162, 0.2)" 
                    title="Smile For Speech" 
                    tag="/ Assist App"
                    onClick={() => window.open('/projects/smile4speech')}/>
                <Work 
                    image={sen} 
                    // shadowColor="rgba(120, 140, 56, 0.2)" 
                    title="Sen (Forest)" 
                    tag="/ Playscape"
                    onClick={() => window.open('/projects/sen-forest')}/>
                <Work 
                    image={interior} 
                    // shadowColor="rgba(217, 191, 152, 0.2)" 
                    title="Interior" 
                    tag="/  Interior Rendering"
                    onClick={() => window.open('/projects/interior')}/>

            </div>

            <div className={classes.Column2}>
                <Work 
                    image={swatch} 
                    // shadowColor="rgba(174, 183, 214, 0.2)" 
                    title="Murakami" tag="/  Graphic"
                    onClick={() => window.open('/projects/murakami-swatch')}/>
                <Work 
                    image={lexigonImage} 
                    // shadowColor="rgba(231, 173, 109, 0.2)" 
                    title="Lexigon" tag="/ Lighting"
                    onClick={() => window.open('/projects/lexigon')}/>
                <Work 
                    image={aural} 
                    // shadowColor="rgba(41, 47, 77, 0.2)" 
                    title="Aural Aid" tag="/  Visual Merchandising"
                    onClick={() => window.open('/projects/aural-aid')}/>
                <Work 
                    image={unfold} 
                    // shadowColor="rgba(66, 147, 140, 0.2)" 
                    title="Unfold the Untold" tag="/ Retail"
                    onClick={() => window.open('/projects/unfold-the-untold')}/>
                <Work 
                    image={flickeringImage} 
                    // shadowColor="rgba(188, 175, 166, 0.2)" 
                    title="Flickering" tag="/ Candle Holder"
                    onClick={() => window.open('/projects/flickering')}/>
                <Work 
                    image={branding} 
                    // shadowColor="rgba(224, 7, 12, 0.2)" 
                    title="Branding Collection" tag="/  Branding"
                    onClick={() => window.open('/projects/branding')}/>
                <Work 
                    image={kakiCircle} 
                    // shadowColor="rgba(158, 46, 36, 0.2)" 
                    title="Kaki Circle" tag="/ Social App"
                    onClick={() => window.open('/projects/kaki-circle')}/>
                <Work 
                    image={musee} 
                    // shadowColor="rgba(119, 105, 64, 0.2)" 
                    title="musee*" tag="/ Museum"
                    onClick={() => window.open('/projects/musee')}/>
            </div>
      </div> 
    )
}

export default all;