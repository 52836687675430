import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import classes from './Contact.module.css';
import db from './firebase';

class Contact extends Component {
  state = {
    name: '',
    phone: '',
    email: '',
    message: ''
  };

  postDataHandler = (e) => {
    e.preventDefault();
    const Data = {
      name: this.state.name, 
      phone: this.state.phone, 
      email: this.state.email, 
      message: this.state.message
    }
    db.post('/contact.json', Data).then(response => console.log(response));
  }

  render(){
    return (
      <div className={classes.Contact}>
        <div 
          id="contactTag" 
          style={{position: "absolute", top: -80 , width: "100%", height: 5, backgroundColor: "black", visibility: "hidden"}}
        ></div>
        <h1 className={classes.Title}>Contact</h1>
        <div className={classes.Details}>
          <div className={classes.Info}>
            <FontAwesomeIcon icon={faEnvelope} />
            <p>a.nguyen1506@gmail.com</p>
          </div>
          <div className={classes.Info}>
            <FontAwesomeIcon icon={faMobileAlt} />
            <p>(+65) 93649501</p>
          </div>
          <div className={classes.Info}>
            <FontAwesomeIcon icon={faLinkedin} />
            <a href="https://www.linkedin.com/in/anh-nguyen-662866156/" target="blank">www.linkedin.com/in/<br/>anh-nguyen-662866156/</a>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact;