import React from "react";
import classes from './PastExp.module.css';

function PastExp() {
  return (
    <div className={classes.PastExp}>
      <h1 className={classes.Title}>Past Learning Sites</h1>
      <div className={classes.TimeLine}>
        <div className={classes.TimeAxis}></div>
        <div className={classes.TimePoint1}></div>
        <div className={classes.TimePoint2}></div>
        <div className={classes.TimePoint3}></div>
        <div className={classes.TimePoint4}></div>
        <div className={classes.TimePoint5}></div>

        <div className={classes.Site1}>
          <h6>08/2020 - present</h6>
          <h4>Design Incubation Centre (NUS)</h4>
          <h5>Designer</h5>
          <p>Develop musee*, the project selected for incubation by the centre | 
          Participate in collaborative project with MBI | Develop the centre's main website
          </p>
        </div>
        <div className={classes.Site2}>
          <h6>08/2020 - 05/2020</h6>
          <h4>NUS Museum</h4>
          <h5>Exhibition Design Intern</h5>
          <p>Research on pedagogies, educational strategies and how to translate these findings into the museum space | 
          Explore the concept of placemaking and its application</p>
        </div>
        <div className={classes.Site3}>
          <h6>08/2018 - 12/2018</h6>
          <h4>Modellscape Studio</h4>
          <h5>3D Design Intern</h5>
          <p>Design key visuals, exhibition booths, events and conferences display | 
          Assist in preparing materials for tender submission based on design brief</p>
        </div>
        <div className={classes.Site4}>
          <h6>06/2018 - 02/2018</h6>
          <h4>Aural-Aid</h4>
          <h5>Product Design Intern</h5>
          <p>Design and render visual materials used as illustration of products on website | 
          Benchmarking existing acoustic products to obtain perspective and generate design concepts.</p>
        </div>
        <div className={classes.Site5}>
          <h6>01/2017 - 06/2017</h6>
          <h4>National University of Singapore</h4>
          <h5>Part Time Researcher</h5>
          <p>Ideate lightweight structures based on sponges | Create models for 3D printing |  
          Design visual presentation of data and research outcomes for paper publication</p>
        </div>
      </div>
    </div>
  )
}

export default PastExp;