import React, { Component } from "react";

import SlideShow from '../component/SlideShow/SlideShow';
import Elaboration from '../component/Elaboration/Elaboration';

// import placeholderImg from '../../../assets/img-placeholder.png';
// import placeholderVid from '../../../assets/vid-placeholder.png';

import heroImgCAID from "../../../assets/visual/caid-hero.jpg";
import img1CAID from "../../../assets/visual/caid/caid-1.jpg";
import img2CAID from "../../../assets/visual/caid/caid-2.jpg";
import img3CAID from "../../../assets/visual/caid/caid-3.jpg";
import img4CAID from "../../../assets/visual/caid/caid-4.jpg";

import heroImgAural from "../../../assets/visual/aural-hero.jpg";
import img1Aural from "../../../assets/visual/aural/aural-1.jpg";
import img2Aural from "../../../assets/visual/aural/aural-2.jpg";
import img3Aural from "../../../assets/visual/aural/aural-3.jpg";
import img4Aural from "../../../assets/visual/aural/aural-4.jpg";
import img5Aural from "../../../assets/visual/aural/aural-5.jpg";
import img6Aural from "../../../assets/visual/aural/aural-6.jpg";

import heroImgInterior from "../../../assets/visual/interior-hero.jpg";
import img1Interior from "../../../assets/visual/interior/interior-1.jpg";
import img2Interior from "../../../assets/visual/interior/interior-2.jpg";
import img3Interior from "../../../assets/visual/interior/interior-3.jpg";
import img4Interior from "../../../assets/visual/interior/interior-4.jpg";

import heroImgMurakami from "../../../assets/visual/murakami-hero.jpg";
import img1Murakami from "../../../assets/visual/murakami/murakami-1.jpg";
import img2Murakami from "../../../assets/visual/murakami/murakami-2.jpg";
import img3Murakami from "../../../assets/visual/murakami/murakami-3.jpg";
import img4Murakami from "../../../assets/visual/murakami/murakami-4.jpg";
import img5Murakami from "../../../assets/visual/murakami/murakami-5.jpg";
import img6Murakami from "../../../assets/visual/murakami/murakami-6.jpg";

import heroImgSketches from "../../../assets/visual/sketches-hero.jpg";
import img1Sketches from "../../../assets/visual/sketches/sketches-1.jpg";
import img2Sketches from "../../../assets/visual/sketches/sketches-2.jpg";
import img3Sketches from "../../../assets/visual/sketches/sketches-3.jpg";
import img4Sketches from "../../../assets/visual/sketches/sketches-4.jpg";
import img5Sketches from "../../../assets/visual/sketches/sketches-5.jpg";
import img6Sketches from "../../../assets/visual/sketches/sketches-6.jpg";

import heroImgBranding from "../../../assets/visual/branding-hero.gif";
import img1Raucous from "../../../assets/visual/branding/raucous-1.jpg";
import img2Raucous from "../../../assets/visual/branding/raucous-2.jpg";
import vid1Stairwell from "../../../assets/visual/branding/stairwell-video-1-opt.mp4";
import vid2Stairwell from "../../../assets/visual/branding/stairwell-video-2-opt.mp4";
import img1DBS from "../../../assets/visual/branding/dbs-1.jpg";
import img2DBS from "../../../assets/visual/branding/dbs-2.jpg";

import classes from './Visual.module.css';

export class CAID extends Component {
     render() {
          return (
          <div className={classes.Projects}>
            <SlideShow 
              color="85, 25, 22"
              heroImage={heroImgCAID}
              imagePositionY="center"
              title="CAID"
              category="Visual"
              type="Modelling & Rendering"
              description="Computer-aided Industrial Design exercises to explore basic functions of Rhino and Keyshot"
              heading1="Year"
              info1="2017"
              heading2="Instructor"
              info2="Martin Danzer"

              back="/projects/musee"
              next="/projects/aural-aid"
            />

            <Elaboration              
              style={{
                gridTemplateRows: "400px 200px 400px 400px 200px 400px"
              }}
            >
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage: `url(${img1CAID})`,
                  gridColumn: "1",
                  gridRow: "1 / span 2",
                  // backgroundSize: "contain",
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(85, 25, 22, 0.1)`,
                  gridColumn: "2",
                  gridRow: "1",
                }}       
              >
                In the very first year of my exposure to design, I had the opportunity to take a course on 3D modelling 
                using Rhino and rendering using Keyshot. The main purpose of the course was to help us get to know the 
                variety of functions and tools that these softwares offer, so that we are aware of the possibilities that 
                they enable and the different scenarios they can be utilized. However, evetually what we learnt extended beyond 
                these practical aspects.
              </div>
              <div 
                className={classes.ImgV}
                style={{
                  backgroundImage: `url(${img2CAID})`,
                  gridColumn: "2",
                  gridRow: "2 / span 3",
                  backgroundPositionX: "left"
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(85, 25, 22, 0.1)`,
                  gridColumn: "1",
                  gridRow: "3",
                }}       
              >
                Throughout the course, we were given different design briefs, most of which were quite open-ended and 
                hence gave us the freedom to conceptualize and visualize our ideas. The first part of the course focused 
                on creating abstract entities such as gestalt, boolean or revolving objects; while the second part steered 
                towards more realistic creations, such as household appliances or lightweight structures. The flow of the course 
                allows us to explore the field of design through different perspectives and methodologies.  
              </div>
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage: `url(${img3CAID})`,
                  gridColumn: "1",
                  gridRow: "4 / span 2",
                  // backgroundSize: "contain",
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(85, 25, 22, 0.1)`,
                  gridColumn: "1",
                  gridRow: "6",
                }}       
              >
                The different projects also gave us chances to improve on skills that a designer needs, such as product presentation 
                or attention to details. Experimentation with forms, materials and lightings also enhanced our sense of aesthetics
                and trained us to create visually pleasing products. Overall, the whole experience was a great beginning to our journey 
                as designers. It gave us many hands-on tasks, during which some of the very first works in our portfolio were created,
                 and equipped us with a valuable set of skills that remain useful and relevant in our carrer. 
              </div>
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage: `url(${img4CAID})`,
                  gridColumn: "2",
                  gridRow: "5 / span 2",
                  // backgroundSize: "contain",
                }}
              ></div>
            </Elaboration>
          </div>
        );
    }
  }

  export class Aural extends Component {
    render() {
         return (
         <div className={classes.Projects}>
           <SlideShow 
             color="127, 91, 82"
             heroImage={heroImgAural}
             imagePositionY="bottom"
             title="Aural-Aid"
             category="Visual"
             type="Visual Merchandising"
             description="A series of rendered images showcasing Aural-Aid’s acoustic panels and structures 
             in various settings"
             heading1="Year"
             info1="2018"
             heading2="Supervisor"
             info2="Aural-Aid Pte. Ltd."

             back="/projects/caid"
             next="/projects/interior"
           />

           <Elaboration              
             style={{
               gridTemplateRows: "400px 200px 400px 600px 400px 200px 400px"
             }}
           >
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img1Aural})`,
                 gridColumn: "1",
                 gridRow: "1 / span 2",
                 // backgroundSize: "contain",
               }}
             ></div>
             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(127, 91, 82, 0.1)`,
                 gridColumn: "2",
                 gridRow: "1",
               }}       
             >
               My internship at Aural-Aid marked my first exposure to the job scope of a visual merchandiser, as well as
               to 3dsmax and Vray, softwares that are more commonly used in spatial design. As compared to Rhino and Keyshot -
                which I was already familiar with - 3dsmax and Vray gave users more autonomy and control over the creation of
                their images. The results are highly realistic renders of large-scale setups, which I personally felt were more
                difficult to achieve with Keyshot. 
             </div>
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img2Aural})`,
                 gridColumn: "2",
                 gridRow: "2 / span 2",
                 // backgroundSize: "contain",
               }}
             ></div>
             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(127, 91, 82, 0.1)`,
                 gridColumn: "1",
                 gridRow: "3",
               }}       
             >
               However, more controls also meant more complexity in usage, as a deeper understanding of the different parameters 
               that affect the final images is essential. Since the product featured was a large-scale structure of acoustic panels, 
               the main goal of the images was not only to highlight this product, but also to portray how it can fit seamlessly 
               into its surroundings without disrupting the visual harmony of the space. Integrating the product into the scene 
               was hence a demanding yet exciting challenge.
             </div>

             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img3Aural})`,
                 gridColumn: "1",
                 gridRow: "4",
                 // backgroundSize: "contain",
               }}
             ></div>
                          <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img4Aural})`,
                 gridColumn: "2",
                 gridRow: "4",
                 // backgroundSize: "contain",
               }}
             ></div>

             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(127, 91, 82, 0.1)`,
                 gridColumn: "2",
                 gridRow: "5",
               }}       
             >
               Understanding the philosophy of the company was also very important as it directly affected the color schemes and 
               props used for the images. Given the keywords 'modern' and 'playful', researches on how to visually translate these adjectives
                were conducted. In the renders, trendy materials and colours - such as marble, rose gold and pastel palette - were used alongside
                with more classic ones - such as light wood, cement and muted colours - to create a sense of balance between modernity and simplicity.
                Props like bean bags and stuffed toys were also included to touches of playfulness.
             </div>
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img5Aural})`,
                 gridColumn: "1",
                 gridRow: "5 / span 2",
                 // backgroundSize: "contain",
               }}
             ></div>
             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(127, 91, 82, 0.1)`,
                 gridColumn: "1",
                 gridRow: "7",
               }}       
             >
               Given the chance to engage deeply in one project throughout my internship, I also familiarized
               myself with the procedure and the stages involved. From understanding the brief and the client's preferences, researching
                and creating moodboards to communicate my direction, to constantly improving based on feedbacks and suggestions - 
                these experiences are relevant in almost any project across many different fields of design. Overall, my internship at 
                Aural-Aid was a valuable and unique experience that helped me know more about the culture of startup companies, as well as 
                the work experience of designers in a commercial context.
             </div>
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img6Aural})`,
                 gridColumn: "2",
                 gridRow: "6 / span 2",
                 backgroundPositionY: "bottom"
                 // backgroundSize: "contain",
               }}
             ></div>
           </Elaboration>
         </div>
       );
   }
 }

 export class Interior extends Component {
  render() {
       return (
       <div className={classes.Projects}>
         <SlideShow 
           color="172, 76, 67"
           heroImage={heroImgInterior}
           imagePositionY="center"
           title="Interior"
           category="Visual"
           type="Interior Rendering"
           description="A series of exploratory renders using various visual styles to improve my rendering and photo editing skills, as well as my flair in interior design."
           heading1="Year"
           info1="2020"

           back="/projects/aural-aid"
           next="/projects/murakami-swatch"
         />

         <Elaboration              
           style={{
             gridTemplateRows: "400px 200px 400px 400px 200px 400px"
           }}
         >
           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img1Interior})`,
               gridColumn: "1",
               gridRow: "1 / span 2",
               // backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(172, 76, 67, 0.1)`,
               gridColumn: "2",
               gridRow: "1",
             }}       
           >
             While serving as a side project to improve my rendering and photo editing skills, this series of images were also 
             borne out of my fondness of furniture and interior styling. To design a living space is a highly challenging yet exciting task - 
             it goes beyond merely aesthetically pleasant setups as an understanding of human beings’ habits, routine and living flow is 
             essential. Therefore, a lot of experimentations took place in this project, not just with colors and materials, but also with 
             choices and positioning of objects.
           </div>
           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img2Interior})`,
               gridColumn: "2",
               gridRow: "2 / span 2",
               // backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(172, 76, 67, 0.1)`,
               gridColumn: "1",
               gridRow: "3",
             }}       
           >
             Utilizing tools that are already available to me, I used Rhino and Keyshot as the main softwares for this project 
             knowing that they may not be the optimal choices. While there were certain limitations, it was exciting to explore functions in 
             Keyshot that I had not worked with in previous product rendering projects, such as certain camera settings or manual placement 
             of light sources. While some post-editing was still needed, overall I found the results obtained by Keyshot exceeding 
             my expectations 
           </div>
           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img3Interior})`,
               gridColumn: "1",
               gridRow: "4 / span 2",
               // backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(172, 76, 67, 0.1)`,
               gridColumn: "2",
               gridRow: "4",
             }}       
           >
             While working on this project, I also chanced upon some post-digital images. Unlike realistic 3D rendering, 
             the post-digital approach involves creating a collage of real objects, people and surfaces. However, the accuracy 
             of lighting and shadow used in the scene is not prioritized, which gives creators more freedom in building their scenes. 
             The results are dream-like images that blur the boundaries between ‘real’ and ‘unreal’. Fascinated by this unique approach,
             I started creating post-digital illustrations of interior scenes as part of my project. 
           </div>
            <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img4Interior})`,
               gridColumn: "2",
               gridRow: "5 / span 2",
               backgroundPositionY: "bottom"
               // backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(172, 76, 67, 0.1)`,
               gridColumn: "1",
               gridRow: "6",
             }}       
           >
             While the process began with simple arrangements of furniture and ornaments, I gradually realized the potential of this 
             approach as a powerful storytelling tool. As soon as a human being enters the scene, a deeper layer of meaning seems to be created. 
             I then experimented further to see how a narrative can become more visible to the viewer, playing with how the human being interacts 
             with other objects, lighting and texture of the scene, or small details that suggest untold stories. The project hence went from 
             creating visually pleasing arrangements to exploring the narratives that a space can unfold.
           </div>
         </Elaboration>
       </div>
     );
 }
}

export class Murakami extends Component {
  render() {
       return (
       <div className={classes.Projects}>
         <SlideShow 
           color="109, 109, 109"
           heroImage={heroImgMurakami}
          //  imagePositionY="center"
           title="Murakami x Swatch"
           category="Visual"
           type="Graphic"
           description="Series of watches (based on Swatch watch template) inspired by Japanese novelist Haruki Murakami's works."
           heading1="Year"
           info1="2017"
           heading2="Instructor"
           info2="Vincent Leow"

           back="/projects/interior"
           next="/projects/sketches"
         />

         <Elaboration              
           style={{
             gridTemplateColumns: "repeat(4, 1fr)",
             gridTemplateRows: "repeat(4, 400px)"
           }}
         >
           <div 
             className={classes.ImgS}
             style={{
               backgroundImage: `url(${img1Murakami})`,
               gridColumn: "1 / span 2",
               gridRow: "1 / span 2",
               backgroundSize: "contain",
               backgroundColor: "#dadade"
             }}
           ></div>
          <div 
             className={classes.ImgS}
             style={{
               backgroundImage: `url(${img3Murakami})`,
               gridColumn: "4",
               gridRow: "2",
               backgroundSize: "contain",
               backgroundColor: "#eaeaea"
             }}
           ></div>

           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(109, 109, 109, 0.1)`,
               gridColumn: "3 / span 2",
               gridRow: "1",
             }}       
           >
            When given the exercise to create a series of designs based on Swatch template, I decided to explore the possibility of
             a collaboration between the brand and the renowned Japanese author Haruki Murakami. Murakami’s books are full of iconic 
             imagery that, if utilized effectively, can immediately form an association with his original works in the audience’s mind. 
             My goal was hence creating subtle designs that are both visually attractive to the general consumers and meaningful to those 
             who are also fans of this author.
           </div>

           <div 
             className={classes.ImgS}
             style={{
               backgroundImage: `url(${img2Murakami})`,
               gridColumn: "3",
               gridRow: "2",
               backgroundSize: "contain",
               backgroundColor: "#f4eae1"
             }}
           ></div>
           <div 
             className={classes.ImgS}
             style={{
               backgroundImage: `url(${img5Murakami})`,
               gridColumn: "1",
               gridRow: "4",
               backgroundSize: "contain",
               backgroundColor: "#eff0e9"
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(109, 109, 109, 0.1)`,
               gridColumn: "1 / span 2",
               gridRow: "3",
             }}       
           >
             The creation of each design involves a revision of the corresponding work and extracting the details that are the most memorable 
             and visual to the readers. These details were then graphically illustrated and applied onto the shape of the watch. 
             The background of the dial also required consideration, as it should be iconic yet simple. Finally, a suitable color 
             scheme was chosen for each design, based on both the ambience of the story and references to existing book covers.
           </div>
            <div 
             className={classes.ImgS}
             style={{
               backgroundImage: `url(${img6Murakami})`,
               gridColumn: "2",
               gridRow: "4",
               backgroundSize: "contain",
               backgroundColor: "#f4e3db"
             }}
           ></div>
           <div 
             className={classes.ImgS}
             style={{
               backgroundImage: `url(${img4Murakami})`,
               gridColumn: "3 / span 2",
               gridRow: "3 / span 2",
               backgroundSize: "contain",
               backgroundColor: "#eae8ec"
             }}
           ></div>
         </Elaboration>
       </div>
     );
 }
}

export class Sketches extends Component {
  render() {
       return (
       <div className={classes.Projects}>
         <SlideShow 
           color="0, 0, 0"
           heroImage={heroImgSketches}
           imagePositionY="center"
           title="Sketches"
           category="Visual"
           type="Drawings"
           description="100 sketches done as quick sketch practice. The sketches were drawn with black drawing pen and grey Copic markers."
           heading1="Year"
           info1="2017"
           heading2="Instructor"
           info2="Vincent Leow"

           back="/projects/murakami-swatch"
           next="/projects/branding"
         />

         <Elaboration              
           style={{
             gridTemplateRows: "600px 400px 200px 400px 600px"
           }}
         >
           <div 
             className={classes.ImgH}
             style={{
              //  border: "1px solid rgb(210, 210, 210)",
               backgroundImage: `url(${img1Sketches})`,
               gridColumn: "1",
               gridRow: "1",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.ImgH}
             style={{
              //  border: "1px solid rgb(210, 210, 210)",
               backgroundImage: `url(${img2Sketches})`,
               gridColumn: "2",
               gridRow: "1",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
              //  border: "1px solid rgb(210, 210, 210)",
               backgroundColor: `rgba(109, 109, 109, 0.1)`,
               gridColumn: "2",
               gridRow: "2",
             }}       
           >
            The exercise involded creating 100 sketches over the course of 7 days. The large number suggested that the purpose of the 
            exercise was not creating highly detailed and realistic sketches, but learning to observe and extract the essence of an object. 
            By understanding an object in this way, we could illustrate it in a quick, clean and simple manner that would still efficiently 
            communicate what we had seen to the audience. As we were allowed to sketch anything we wanted, I decided to divide the 100 
            sketches into 10 different topics to explore.
           </div>    
           <div 
             className={classes.ImgH}
             style={{
              //  border: "1px solid rgb(210, 210, 210)",
               backgroundImage: `url(${img3Sketches})`,
               gridColumn: "1",
               gridRow: "2 / span 2",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.ImgH}
             style={{
              //  border: "1px solid rgb(210, 210, 210)",
               backgroundImage: `url(${img4Sketches})`,
               gridColumn: "2",
               gridRow: "3 / span 2",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
              //  border: "1px solid rgb(210, 210, 210)",
               backgroundColor: `rgba(109, 109, 109, 0.1)`,
               gridColumn: "1",
               gridRow: "4",
             }}       
           >
              These topics cover many different kinds of entities, from highly technical objects like vehicles to living things like animals
              or human beings. My goals were to train myself in observing a wide variety of subjects and to express the essence of both 
              non-organic and organic things. Being used to shading with lines, it was also an exciting challenge to practise shading
               using markers. This new approach has trained me to observe and analyze light and shadow into definitive zones, which resulted in
               much cleaner looking sketches.
           </div>   
           <div 
             className={classes.ImgH}
             style={{
              //  border: "1px solid rgb(210, 210, 210)",
               backgroundImage: `url(${img5Sketches})`,
               gridColumn: "1",
               gridRow: "5",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.ImgH}
             style={{
              //  border: "1px solid rgb(210, 210, 210)",
               backgroundImage: `url(${img6Sketches})`,
               gridColumn: "2",
               gridRow: "5",
               backgroundSize: "contain",
             }}
           ></div>         
         </Elaboration>
       </div>
     );
 }
}

export class Branding extends Component {
  render() {
       return (
       <div className={classes.Projects}>
         <SlideShow 
           color="124, 26, 45"
           heroImage={heroImgBranding}
           imagePositionY="center"
           title="Branding Collection"
           category="Visual"
           type="Branding"
           description="3 different branding projects completed during 2018 - 2019, each was created in a unique context and serving a unique purpose."
           heading1="Year"
           info1="2018-2019"

           back="/projects/sketches"
           next="/projects/circlets"
         />

         <Elaboration              
           style={{
             gridTemplateRows: "1000px 400px 800px 400px 1000px 400px"
           }}
         >
           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img1Raucous})`,
               gridColumn: "1 / span 2",
               gridRow: "1",
              //  backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img2Raucous})`,
               gridColumn: "1",
               gridRow: "2",
              //  backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(124, 26, 45, 0.1)`,
               gridColumn: "2",
               gridRow: "2",
             }}       
           ><strong>Raucous</strong>
             Raucous is a branding concept for a hi-fi speaker. The design explores how luxury and exclusivity can emerge beyond 
             unattractive appearance. Inspired by abstract art - something that only a few people can appreciate - the logo is complex and 
             hard to remember. Being cut across in a disruptive and seemingly random manner, the design lets go of its sense of order, and becomes
             something that is difficult to replicate. Since only actual users may recognize the brand from this logo, a small, exclusive group 
             of brand followers is formed. 
           </div>    
            <video 
                width="100%" 
                height="100%" 
                style={{
                  backgroundColor: `black`,
                  gridColumn: "1 / span 2",
                  gridRow: "3",
                }}
                controls
            >
              <source src={vid1Stairwell} type="video/mp4"></source>
            </video>
            <video 
                width="100%" 
                height="100%" 
                style={{
                  backgroundColor: `black`,
                  gridColumn: "1",
                  gridRow: "4",
                }}
                controls
            >
              <source src={vid2Stairwell} type="video/mp4"></source>
            </video>

           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(124, 26, 45, 0.1)`,
               gridColumn: "2",
               gridRow: "4",
             }}       
           ><strong>Stairwell Studio</strong>
             The exercise involved create a bumper and a credit roll based on the chosen group name - Stairwell Studio - using Adobe
             After Effects. We wanted to create an animated footage that features a staircase, and decided to refer to Escher stairs for 
             inspiration. A red ball was added to attract audience's attention and emphasize the impossible flow of the stairs, while the
              whole setup was also incorporated into the studio name. The credit utilized the same elements in simpler setups to create a sense
               of coherence in identity.

           </div>    

           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img1DBS})`,
               gridColumn: "1 / span 2",
               gridRow: "5",
               backgroundSize: "contain",
               backgroundColor: `black`,
             }}
           ></div>
           <div 
             className={classes.ImgH}
             style={{
               backgroundImage: `url(${img2DBS})`,
               backgroundColor: 'black',
               gridColumn: "1",
               gridRow: "6",
               backgroundSize: "contain",
             }}
           ></div>
           <div 
             className={classes.Text}
             style={{
               backgroundColor: `rgba(124, 26, 45, 0.1)`,
               gridColumn: "2",
               gridRow: "6",
             }}       
           ><strong>Social Enterprise Summit 2018</strong>
           During my internship with Modellscape Studio, I had the chance to work on the key visual for the Social Enterprise Summit 2018
            organized by DBS Foundation. The topic of the summit revolved around the blurring boundary between social imapact and profit, and
             3 keywords - 'red', 'blurred' and 'line' - were identified. My task involved creating a moodboard that best demonstrates 
             how these elements can work together and generating the final design based on what I found.  

           </div>   
           
         </Elaboration>
       </div>
     );
 }
}


