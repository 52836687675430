import React from "react";
import Content from './components/Content/Content';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import UpButton from './components/UpButton/UpButton';
import classes from './App.module.css';

function App() {
  return (
    <div className={classes.App}>  
      <NavBar/>
      <Content/>
      <Footer/>
      <UpButton/>
    </div>
  )
}

export default App;
