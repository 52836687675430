import classes from './SideBar.module.css';
import React from 'react';
import {Link} from 'react-router-dom';

const sideBar = () => {
    let tabArr = document.querySelectorAll(`.${classes.Tab}`);

    // function clickHandler(obj, string){
        
        // tabArr.forEach((t) => {
        //     if(t.innerText.indexOf('/ ') !== -1){
        //         // console.log(t.innerText);
        //         t.innerText = t.innerText.replace('/ ', '');
        //         t.style.color = "black";
        //     }
        // })

        // let currText = obj.target.innerText;
        // obj.target.innerText = '/ ' + currText;
        // obj.target.style.color = "rgba(249, 171, 0, 1)";

    //     window.location.href = `/works/${string}`;
    // }

    let toRender;
    // if (window.innerWidth >= 800) {
        toRender = (
            <div className={classes.SideBar}>
                <Link 
                    className={window.location.href.indexOf('product') !== -1 ? classes.CurrentTab : classes.Tab} 
                    // onClick={window.location.href.indexOf('product') !== -1 ? () => {return;} : (e) => {clickHandler(e, "product")}}
                    to={'/works/product'}
                >
                    {window.location.href.indexOf('product') !== -1 ? "/ Product Design" : "Product Design"}
                </Link>
                <Link 
                    className={window.location.href.indexOf('service') !== -1 ? classes.CurrentTab : classes.Tab} 
                    // onClick={window.location.href.indexOf('service') !== -1 ? () => {return;} : (e) => {clickHandler(e, "service")}}
                    to={'/works/service'}
                >
                    {window.location.href.indexOf('service') !== -1 ? "/ Service Design" : "Service Design"}
                </Link>
                <Link 
                    className={window.location.href.indexOf('uiux') !== -1 ? classes.CurrentTab : classes.Tab} 
                    // onClick={window.location.href.indexOf('uiux') !== -1 ? () => {return;} : (e) => {clickHandler(e, "uiux")}}
                    to={'/works/uiux'}
                >
                    {window.location.href.indexOf('uiux') !== -1 ? "/ UI/UX Design" : "UI/UX Design"}
                </Link>
                <Link 
                    className={window.location.href.indexOf('visual') !== -1 ? classes.CurrentTab : classes.Tab} 
                    // onClick={window.location.href.indexOf('visual') !== -1 ? () => {return;} : (e) => {clickHandler(e, "visual")}}
                    to={'/works/visual'}
                >
                    {window.location.href.indexOf('visual') !== -1 ? "/ Visual Design" : "Visual Design"}
                </Link>
                <Link 
                    className={window.location.href.indexOf('all') !== -1 ? classes.CurrentTab : classes.Tab} 
                    // onClick={window.location.href.indexOf('all') !== -1 ? () => {return;} : (e) => {clickHandler(e, "all")}}
                    to={'/works/all'}
                >
                    {window.location.href.indexOf('all') !== -1 ? "/ All" : "All"}
                </Link>
            </div>  
        ) 
        //} else {
        //     toRender = (<div className={classes.SideBar}>
        //         <span 
        //             className={window.location.href.indexOf('product') !== -1 ? classes.CurrentTab : classes.Tab} 
        //             onClick={window.location.href.indexOf('product') !== -1 ? () => {return;} : (e) => {clickHandler(e, "product")}}
        //             onMouseEnter={(e) => {setTimeout(() => e.target.innerText = "Product Design", 200)}}
        //             onMouseLeave={(e) => {e.target.innerText = "P"}}
        //         >
        //         P    
        //         </span>
        //         <span 
        //             className={window.location.href.indexOf('service') !== -1 ? classes.CurrentTab : classes.Tab} 
        //             onClick={window.location.href.indexOf('service') !== -1 ? () => {return;} : (e) => {clickHandler(e, "service")}}
        //             onMouseEnter={(e) => {setTimeout(() => e.target.innerText = "Service Design", 200)}}
        //             onMouseLeave={(e) => {e.target.innerText = "S"}}
        //         >
        //         S    
        //         </span>
        //         <span 
        //             className={window.location.href.indexOf('uiux') !== -1 ? classes.CurrentTab : classes.Tab} 
        //             onClick={window.location.href.indexOf('uiux') !== -1 ? () => {return;} : (e) => {clickHandler(e, "uiux")}}
        //             onMouseEnter={(e) => {setTimeout(() => e.target.innerText = "UI/UX Design", 200)}}
        //             onMouseLeave={(e) => {e.target.innerText = "U"}}
        //         >
        //         U    
        //         </span>
        //         <span 
        //             className={window.location.href.indexOf('visual') !== -1 ? classes.CurrentTab : classes.Tab} 
        //             onClick={window.location.href.indexOf('visual') !== -1 ? () => {return;} : (e) => {clickHandler(e, "visual")}}
        //             onMouseEnter={(e) => {setTimeout(() => e.target.innerText = "Visual Design", 200)}}
        //             onMouseLeave={(e) => {e.target.innerText = "V"}}
        //         >
        //         V    
        //         </span>
        //         <span 
        //             className={window.location.href.indexOf('all') !== -1 ? classes.CurrentTab : classes.Tab} 
        //             onClick={window.location.href.indexOf('all') !== -1 ? () => {return;} : (e) => {clickHandler(e, "all")}}
        //             onMouseEnter={(e) => {setTimeout(() => e.target.innerText = "All", 200)}}
        //             onMouseLeave={(e) => {e.target.innerText = "A"}}
        //         >
        //         A    
        //         </span>
        //     </div>) 
        // }
    return toRender;
}


export default sideBar;