import React, { Component } from "react";

import SlideShow from '../component/SlideShow/SlideShow';
import Elaboration from '../component/Elaboration/Elaboration';

// import placeholderImg from '../../../assets/img-placeholder.png';
// import placeholderVid from '../../../assets/vid-placeholder.png';

// import img4Smile from "../../../assets/service/smile/smile-4.jpg";
// import img5Smile from "../../../assets/service/smile/smile-5.jpg";
import heroImgSmile from "../../../assets/service/smile-hero.jpg";
import img2Smile from "../../../assets/service/smile/smile-2.jpg";
import img3Smile from "../../../assets/service/smile/smile-3.jpg";
import img1Smile from "../../../assets/service/smile/smile-1.jpg";
import vidSmile from "../../../assets/service/smile/smile-video-opt.mp4";

// import img4Kaki from "../../../assets/service/kaki/kaki-4.jpg";
import heroImgKaki from "../../../assets/service/kaki-hero.jpg";
import img1Kaki from "../../../assets/service/kaki/kaki-1.jpg";
import img2Kaki from "../../../assets/service/kaki/kaki-2.jpg";
import img3Kaki from "../../../assets/service/kaki/kaki-3.jpg";
import vidKaki from "../../../assets/service/kaki/kaki-video-opt.mp4";


import classes from './Services.module.css';

export class Smile extends Component {
     render() {
          return (
          <div className={classes.Projects}>
            <SlideShow 
              color="33, 68, 152"
              heroImage={heroImgSmile}
              imagePositionX="left"
              imagePositionY="bottom"
              title="Smile for Speech"
              category="Service"
              type="Communication App"
              description="An application aiming to empower children with Cerebral Palsy by enabling independent communication simply through a smile."
              groupmates="Designed with Phung Tuan Hoang, Tracy Shen & Tran Viet Quang"
              heading1="Year"
              info1="2019"
              heading2=""
              info2=""
              heading3="NUS Makerthon 2019"
              info3="2nd Runner-up"

              back="/projects/flickering"
              next="/projects/kaki-circle"
            />

            <Elaboration              
              style={{
                gridTemplateRows: "400px 400px 800px 400px"
              }}
            >
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(33, 68, 152, 0.1)`,
                  gridColumn: "2",
                  gridRow: "1",
                }}       
              >
                Our persona, Vincent, is a six-year-old boy with Cerebral Palsy - a group of disorders that affect 
                muscle movement and coordination. It causes uncontrolled muscle movements of various levels of severity 
                and body parts affected. As a result, Vincent has limited mobility and communication ability, and hence faces
                lots of difficulties in performing basic, daily activities. However he is able to kick and move his limbs to 
                some extent, and able to smile widely.
              </div>
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage: `url(${img2Smile})`,
                  gridColumn: "1 / span 1",
                  gridRow: "1 / span 1",
                  backgroundSize: "contain"
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(33, 68, 152, 0.1)`,
                  gridColumn: "1 / span 1",
                  gridRow: "2 / span 1",
                }}       
              >
                Just like any other child, Vincent would let his parents know of his needs and opinions
                through his own means. However, much of the communication relies on assistance and 
                interpretation from his parents, which can lead to miscommunication and frustration for
                both parties. Hence, a system that enables independent communication would empower the child 
                and foster his connections with others. 
              </div>
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage: `url(${img3Smile})`,
                  gridColumn: "2 / span 1",
                  gridRow: "2 / span 1",
                  backgroundSize: "contain"
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(33, 68, 152, 0.1)`,
                  gridColumn: "1",
                  gridRow: "4",
                }}       
              >
                Our project - Smile for Speech - is a communication app which is controlled by a simple smile.
                Considering the variety in conditions and movement limitation of children with Cerebral Palsy, 
                a smile is the most definite and consistent gesture that children could make. Our app captures
                the smile through the front camera of the IPad, which would then be used as a switch to select
                the buttons on the interface. As the cursor of our interface moves by itself in sequence, just
                one smile is needed to make the selection.
              </div>
              <div 
                className={classes.ImgH}
                style={{
                  backgroundImage: `url(${img1Smile})`,
                  gridColumn: "1 / span 2",
                  gridRow: "3 / span 1",
                  backgroundSize: "contain",
                }}
              ></div>
              <div 
                className={classes.Text}
                style={{
                  backgroundColor: `rgba(33, 68, 152, 0.1)`,
                  gridColumn: "2",
                  gridRow: "4",
                }}       
              >
                Our app has 3 levels of difficulty to match the different developmental stages of the children. 
                Level 1 features familiar environments with phrases related to different objects. 
                Children would select the phrase and there is no need to form sentences. 
                Level 2 allows for forming of simple sentences by breaking the sentences into smaller parts and 
                compartmentalizing the commonly used phrases. 
                Level 3 is the most complex and would require linguistic skills as they could use a keyboard 
                with predictive text to assist in completing sentences.
              </div>

            </Elaboration>
            <video 
                style={{backgroundColor: "black"}}
                width="100%" 
                controls
            >
              <source src={vidSmile} type="video/mp4"></source>
            </video>
          </div>
        );
    }
  }


  export class Kaki extends Component {
    render() {
         return (
         <div className={classes.Projects}>
           <SlideShow 
             color="158, 46, 36"
             heroImage={heroImgKaki}
             title="Kaki Circle"
             category="Service"
             type="Social App"
             description="A virtual platform that aims to avert loneliness and depression among 
             isolated elderly by creating opportunities for them to form social connections with other elderly."
             groupmates="Designed with Phung Tuan Hoang, Delia Lim & Daniel Teo"
             heading1="Year"
             info1="2020"
             heading2=""
             info2=""
             heading3="NUS Makerthon 2020"
             info3="2nd Runner-up"

             back="/projects/smile4speech"
             next="/projects/shape-of-motion"
           />

           <Elaboration              
             style={{
               gridTemplateRows: "400px 400px 800px 400px"
             }}
           >
             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(158, 46, 36, 0.1)`,
                 gridColumn: "2",
                 gridRow: "1",
               }}       
             >
              By 2030, there will be about 83 thousands elderly in Singapore, twice as much as there were in 2016.
              Among these elderly, 1 out of 5 suffers from depression.
              It has also been known that elderlies living alone are twice likely to have depression, compared to those living with others.
              Hence, Kaki Circle came about with an aim to help isolated elderlies living alone, by encouraging them to form social connections 
              with other elderlies, enhancing their self-reliance and long-term mental well-being.
             </div>
             <div 
               className={classes.ImgH}
               style={{
                 backgroundImage: `url(${img3Kaki})`,
                 gridColumn: "1",
                 gridRow: "1",
                 backgroundSize: "contain"
               }}
             ></div>
             <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(158, 46, 36, 0.1)`,
                 gridColumn: "1",
                 gridRow: "2",
               }}       
              >
                Our team has learnt that many elderlies who live alone actually yearn for social interactions.
                However, they are held back by their physical mobility, awkwardness and lack of information about social happenings.
                To overcome these obstacles, we thought of how technology can enable isolated elderlies to take the first steps in 
                interacting with others at the comfort of their own home, setting the foundation for long-term, meaningful companionships.
             </div>
             
             <div 
               className={classes.ImgS}
               style={{
                 backgroundImage: `url(${img2Kaki})`,
                 gridColumn: "1",
                 gridRow: "3",
                //  backgroundSize: "contain"
               }}
             ></div>

              <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(158, 46, 36, 0.1)`,
                 gridColumn: "1",
                 gridRow: "4",
               }}       
              >
                The journal 'Frontiers in Psychiatry' has pointed out that people tend to engage in greater
                self-disclosure during computer-mediated communication, as compared to face-to-face interactions.
                It means this medium has stronger potential to facilitate development of relationships.
               Online interactions are also known to enable better communication due to the sense anonymity
               they provide to their users.
              </div>

              <div 
               className={classes.ImgV}
               style={{
                 backgroundImage: `url(${img1Kaki})`,
                 gridColumn: "2",
                 gridRow: "2 / span 2",
               }}
             ></div>
              <div 
               className={classes.Text}
               style={{
                 backgroundColor: `rgba(158, 46, 36, 0.1)`,
                 gridColumn: "2",
                 gridRow: "4",
               }}       
              >
               Following this principle, Kaki Circle first aims to encourage elderlies to interact with others through a virtual space,
               via chatting or playing games, while the elderlies can remain in their comfort zone. Once they have become more confident
               and acquainted with their social connections, Kaki Circle then aims to bring these online bonds formed into reality 
               by prompting the elderlies to go for RC events or hang out with one another in person.
              </div>

           </Elaboration>
           <video 
                style={{backgroundColor: "black"}}
                width="100%" 
                controls
            >
              <source src={vidKaki} type="video/mp4"></source>
            </video>
         </div>
       );
   }
 }
  