import React from "react";
import classes from './Intro.module.css';

function Intro() {
  return (
    <div className={classes.Intro}>
        <div 
          id="aboutTag" 
          style={{position: "absolute", top: -50 , width: "100%", height: 5, backgroundColor: "black", visibility: "hidden"}}
        ></div>
        <div className={classes.Portrait}>
        <div className={classes.PortraitPhoto}></div>
        </div>
        <div className={classes.Introduction}>
        <div className={classes.IntroductionContainer}>
            <h1 className={classes.Name}>Hoang Anh</h1>
            <p className={classes.IntroductionText}>
            <div style={{maxWidth: 400, font: "Roboto", fontWeight: 500, fontSize: 18, color: "var(--main-color)", marginBottom: 10}}>An eager learner who does not shy away from new exposures</div> 
            I believe being a good designer requires constant learning, 
            as a diverse skill set will allow the designer to be flexible, adaptive and fully prepared 
            for the constant changes that the world is going through on a daily basis.
            </p>
            <a 
              style={{color: "white"}}
              target="blank"
              className={classes.DownloadButton} 
              href="https://drive.google.com/file/d/1NRnw7NpD8J71PrlpvmvkY7Jy8cLWOWOJ/view?usp=sharing"
            >Download Resume</a>
        </div>
        </div>
    </div>
  )
}

export default Intro;